export type CardInTenantData = {
    id?: number,
    tenantId: number,
    tenantName: string,
    machineId: number,
    machineName: string,
    cardId: number,
    cardInMachineId: number,
    cardUuid?: string,
    remark?: string,
    createdBy?: string;
    updatedBy?: string;
    deletedBy?: string;
    createdAt?: string;
    updatedAt?: string;
    deletedAt?: string;
}

export type CardInTenantResponseBody = {
    data: CardInTenantData,
    status: number,
    message: string,
}
  
export type CardInTenantGETRequestBody = {
    tenantId: number,
    limit?: number,
    offset?: number
}

export type CardInTenantADDRequestBody = {
    tenantId: number,
    machineId: number,
    cardId: number,
    cardInMachineId: number,
    remark?: string,
    createdBy: string,
}

export type CardInTenantDELETERequestBody = {
    id: number,
    deletedBy: string
}

export type DeleteCard = {
    id: number,
    name?: string | number,
}
  
export const CardDefault = {
    machineId: 0,
    machineName: "",
    cardId: 0,
    cardInMachineId: 0,
    remark: "",
}
