import React from "react";
import MetalImage from "../../assets/images/metal-icon.jpeg"; 

const MetalIcon = () => {
    return (
        <div>
            <img 
                src={MetalImage} 
                alt="Plastic Icon" 
                style={{ width: "25px", height: "25px"}}
            />
        </div>
    );
}

export default MetalIcon;
