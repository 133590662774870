import styled from "styled-components";

export const CardContainer = styled.section`
  display: grid;
  grid-template-columns repeat(6, 1fr);
  grid-gap: 20px;
  margin-bottom: 30px;
  width: 100%;
  h2 {
    color: #000;
  }
  h3 {
    font-size: 200%;
  }
  @media screen and (max-width: 900px) {
    grid-template-columns repeat(3, 1fr);
  }
  @media screen and (max-width: 650px) {
    width: 100%;
      display: flex;
      flex-direction: column;
      font-size: 80%;
    }
`;

export const ChartContainer = styled.section`
  display: grid;
  grid-template-columns repeat(2, 1fr);
  grid-gap: 20px;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 30px;
  h2 {
    color: #000;
  }
  h3 {
    font-size: 200%;
  }

  @media screen and (max-width: 1200px) {
    grid-template-columns repeat(1, 1fr);
  }
  @media screen and (max-width: 650px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 80%;
    margin-bottom: 20px;
  }
`;

export const LargeChartContainer = styled.section`
  display: grid;
  grid-template-columns repeat(1, 1fr);
  width: 100%;
  h2 {
    color: #000;
  }
  h3 {
    font-size: 200%;
  }
  @media screen and (max-width: 650px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 80%;
  }
`;

export const Title = styled.section`
    color: #808080; !important;
    font-size: .8em;
    font-weight: bold;
    margin-bottom: 5px;
`;

export const FilterContainer = styled.div`
  min-width: 300px;
`
export const PaginationWrapper = styled.section`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
`;

export const ParentContainer = styled.section`
  background-color: #fff;
  padding: 20px;
  min-height: 450px;
  max-height: 600px;
  margin-bottom: 10px;

  display: flex;
  justify-content: center; /* Horizontally center */
  align-items: center; /* Vertically center */
  flex-direction: column;
`;