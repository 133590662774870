import React, {  useEffect, useState } from "react";
import { Bar } from 'react-chartjs-2';
import { ChartData } from 'chart.js';
import Pagination from '@mui/material/Pagination';
import { BarChartWeightRequestType, BarChartWeightResponseType } from "../../../types/NewDashboardType";
import { getWeightApi } from "../../../services/RouteServices/NewDashboardApi";
import { STATUS_CODES } from "../../../constants/GlobalConstants";
import { weightOptions } from "../WeightChartOptions";
import { DateFilterValue } from "../../../types/global/FilterValueType";
import { convertDateFormat } from "../../../utils/DateTimeUtil";
import {
  ParentContainer,
} from "./../DashboardElements";
import ChartContainer from './ChartContainer';

interface WeightBarChartProps {
  orgId: number;
  locationId: number;
  machineId: number;
  tenantId: number;
  dateFilterValue: DateFilterValue
  barItemColor: string
  apiUrl: string
}

const WeightBarChart: React.FC<WeightBarChartProps> = ({ orgId, locationId, machineId, tenantId, dateFilterValue, barItemColor, apiUrl }) => {
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [labels, setLabels] = useState<string[]>([]);
  const [weights, setWeights] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isChartData, setIsChartData] = useState<boolean>(false);
  const [barChartItemsPerPage, setBarChartItemsPerPage] = useState(10);
  const [dynamicBarPercentage, setDynamicBarPercentage] = useState(1);
  const [weightChartData, setWeightChartData] = useState<ChartData<"bar", number[], unknown>>({
    labels: [],
    datasets: [
      {
        label: 'Total Weight (kg)',
        data: [],
        backgroundColor: barItemColor,
      },
    ],
  });

  useEffect(() => {
    setPage(1);
    getWeightByLocation();
  }, [orgId, locationId, machineId, tenantId, dateFilterValue]);

  useEffect(() => {
    if (labels.length > 0 && weights.length > 0) {
      updateChartData(page);
    }
  }, [labels, weights, page]);

  const getWeightByLocation = () => {
    setIsLoading(true);
    const input : BarChartWeightRequestType  = {
      orgId: orgId,
      locationId: locationId,
      machineId: machineId,
      tenantId: tenantId,
      startDate: convertDateFormat(dateFilterValue?.startDate || ""),
      endDate: convertDateFormat(dateFilterValue?.endDate || ""),
      isForAllOrganization: (orgId <= 0),
    };

    getWeightApi(input, apiUrl)
      .then(res => {
        if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
          const data: BarChartWeightResponseType = res?.data?.data;
          if (data?.weightList && data.weightList.length > 0) {
            loadDataToStates(data);
            if(data?.weightList && data.weightList.length < 2)
            {
              setDynamicBarPercentage(0.2);
            }
            else if(data?.weightList && data.weightList.length < 5)
            {
              setDynamicBarPercentage(0.4);
            }
            else{
              setDynamicBarPercentage(1.5);
            }
          } else {
            clearChart();
          }
        }
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
      });
  };

  const updateChartData = (pageNumber: number) => {
    const startIndex = (pageNumber - 1) * barChartItemsPerPage;
    const endIndex = pageNumber * barChartItemsPerPage;

    setWeightChartData({
      labels: labels.slice(startIndex, endIndex),
      datasets: [
        {
          ...weightChartData.datasets[0],
          data: weights.slice(startIndex, endIndex),
        },
      ],
    });
    if(labels.slice(startIndex, endIndex).length < 2)
    {
      setDynamicBarPercentage(0.2);
    }
    else if(labels.slice(startIndex, endIndex).length < 3)
    {
      setDynamicBarPercentage(0.4);
    }
    else if(labels.slice(startIndex, endIndex).length < 5)
    {
      setDynamicBarPercentage(1);
    }
    else{
      setDynamicBarPercentage(1.5);
    }
  };

  const loadDataToStates = (data: BarChartWeightResponseType) => {
    const updatedLabels = data.weightList.map(item => item.name);
    const updatedData = data.weightList.map(item => item.totalWeight);
    const totalPages = Math.ceil(updatedData.length / barChartItemsPerPage);
    
    const updatedColors = data.weightList.map(item => {
      if (item.deletedAt) {
        return '#686A6C';
      }
      return '#0000FF';
    });

    setIsChartData(true);
    setLabels(updatedLabels);
    setWeights(updatedData);
    setTotalPage(totalPages);
    setPage(1);

    setWeightChartData({
      labels: updatedLabels,
      datasets: [
        {
          label: 'Total Weight (kg)',
          data: updatedData,
          backgroundColor: updatedColors,
        },
      ],
    });
  };
  
  const clearChart = () => {
    setWeightChartData({
      labels: [],
      datasets: [
        {
          label: 'Total Weight (kg)',
          data: [],
          backgroundColor: '#0000FF',
        },
      ],
    });
    setTotalPage(1);
    setPage(1);
    setIsChartData(false);
  };
  const handlePageChange = (event: React.ChangeEvent<unknown>, pageNumber: number) => {
    setPage(pageNumber);
    updateChartData(pageNumber);
  };

  return (
    <ParentContainer>
      <ChartContainer>
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <>
            <Bar options={weightOptions(dynamicBarPercentage)} data={weightChartData!} className="my-chart" />
          </>
        )}
      </ChartContainer>
      {
        isChartData &&
          <Pagination
            count={totalPage}
            page={page}
            color="primary"
            onChange={(e, p) => handlePageChange(e, p)}
          />
      }
    </ParentContainer>
  );
};

export default WeightBarChart;
