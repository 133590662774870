import { ChartOptions } from 'chart.js';
import { wasteTypes } from "../../constants/SystemConstants";
export const weightbyTenantOptions = (dynamicBarPercentage: number): ChartOptions<'bar'> => ({
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: false,
      text: 'Top Contributed Tenant',
      align: 'start',
      position: 'top',
      padding: {
        top: 10,
      },
    },
    tooltip: {
      callbacks: {
        label: function(context: any) {
          const index = context.dataIndex;
          const wasteTypeWeightList = context.dataset.wasteTypeWeightList;
          let labelParts = []; 
          if (wasteTypeWeightList.length > 0) {
            for (let i = 0; i < wasteTypeWeightList[index].length; i++) {
              if (wasteTypeWeightList[index][i].totalWeight > 0) {
                labelParts.push(`${wasteTypes[wasteTypeWeightList[index][i].name]}: ${wasteTypeWeightList[index][i].totalWeight} kg`);
              }
            }
          }
          return labelParts.join(', ');
        }
      }
    },
    datalabels: {
      formatter: (value: number) => {
        return value === 0 ? '' : `${value}`;
      },
      color: '#fff',
    }
  },
  scales: {
    x: {
      title: {
        display: true,
        text: 'Weight Amount (KG)',
      },
      ticks: {
        callback: function (value: any) {
          return value ;
        },
      },
    },
    y: {
      title: {
        display: true,
        text: 'Tenant',
      },
      ticks: {
        autoSkip: false,
        font: {
          size: 14,
          weight: 'bold',
        },
        color: 'black'
      },
    },
  },
  indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 1,
    },
  },
  datasets: {
    bar: {
      barPercentage: dynamicBarPercentage,
      categoryPercentage: 0.5,
    },
  },
});


export const wasteProfileOptions = (totalWeight: number) : ChartOptions<'pie'>  => ({
  responsive: true,
  plugins: {
    legend: {
      position: 'right' as const,
      labels: {
        font: {
          size: 15,
          weight: 'bold',
        },
        color: 'black',
      },
    },
    title: {
      display: true,
      text: 'Total Weight : ' + totalWeight?.toFixed(2) + ' kg',
      align: 'start' as const,
        position: 'top' as const, 
        padding: {
          top: 10, 
        },
      color: 'black',
      font: {
        size: 14, 
      },
    },
    tooltip: {
      callbacks: {
        label: function(context: any) {
          console.log(context)
          let label = '';
          const index = context.dataIndex;
          const weightList = context.dataset.weightList;
          if (context.parsed) {
            label = `: ${context.parsed} % (${weightList[index]} kg)` ;
          }
          return label;
        }
      }
    },
    datalabels: {
      formatter: (value: number, context: any) => {
        const index = context.dataIndex;
          const weightList = context.dataset.weightList;
        return value === 0 ? '' : `${value}% (${weightList[index]} kg)`;
      },
      color: '#fff',
      font: {
        size: 14, 
      },
      anchor: 'center', 
      align: 'center',
    }
  },
});

export const weightOptions = (dynamicBarPercentage: number) => ({
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: false,
      text: 'Total Weight by Location',
      align: 'start' as const,
      position: 'top' as const,
      padding: {
        top: 10,
      },
    },
    datalabels: {
      formatter: (value: number) => {
        return value === 0 ? '' : `${value}`;
      },
      color: '#fff',
    }
  },
  scales: {
    y: {
      title: {
        display: true,
        text: 'Total Weight (KG)',
      },
      ticks: {
        callback: function (value: any) {
          return value;
        },
      },
    },
    x: {
      title: {
        display: false,
        text: 'Name',
      },
    },
  },
  datasets: {
    bar: {
      barPercentage: dynamicBarPercentage,
      categoryPercentage: 0.5,
    },
  },
});

export const weightbyYearsOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Total Weght by Years',
    },
    datalabels: {
      formatter: (value: number) => {
        return value === 0 ? '' : `${value}`;
      },
      color: '#0000FF',
    }
  },
  scales: {
      y: {
        title: {
          display: true,
          text: 'Weight Amount (KG)'
        },
        ticks: {
          callback: function(value: any, index: any, ticks: any) {
            return value ;
          }
        }
      },
      x: {
          title: {
              display: true,
              text: "Years"
          }
      }
  },
  datasets: {
    bar: {
      barPercentage: 1,
      categoryPercentage: 0.5,
    },
  }, 
};
