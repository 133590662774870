import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import ImageCaptureUpload from "./ImageCaptureUpload";
import { OrganizationsData } from "../../../types/OrganizationsType";
import { useFilterOrganizations } from "../../../custom-hooks/useFilterOrganizations";
import { ModalContainer } from "../../Shared/Common/Containers";
import { useFilterMachines } from "../../../custom-hooks/useFilterMachines";
import { MachinesData } from "../../../types/MachinesType";
import { CurrentManualDetection, ManualDetectionData } from "../../../types/ManualDetectionType";
import { useFilterTenants } from "../../../custom-hooks/useFilterTenants";
import { TenantsData } from "../../../types/TenantsType";
import { ChangeEvent } from "react";
import { getCurrentLocalUser, isAdminUser, isTenantUser } from "../../../utils/UserUtil";
import { UserInfo } from "../../../types/UserAuth";

type Props = {
    show: boolean
    manualDetection: CurrentManualDetection
    toggleShow: () => void
    onChange: (event: ChangeEvent<any>) => void
    onUploadImage: (base64Image: string) => void
    onAdd: () => void
}

const AddModal = (props: Props) => {
    const {show, toggleShow, onChange, onUploadImage, onAdd, manualDetection} = props
    const userInfo: UserInfo = getCurrentLocalUser();
    const orgId = (isTenantUser() || isAdminUser()) ? userInfo?.orgId : manualDetection?.organizationId;
    const { machines } = useFilterMachines(orgId, [orgId]);
    const {organizations} = useFilterOrganizations()
    const {tenants} = useFilterTenants(manualDetection?.machineId, [manualDetection?.machineId])
    

    return (  
        <Modal show={show}>
            <Modal.Header>
                <Modal.Title>Upload</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ModalContainer>
                    <ImageCaptureUpload
                        onUploadImage={onUploadImage}
                    />
                    <div className="mt-3 mb-3">
                        <label className="mb-1 fw-bold">Image Type <span className="text-danger">*</span></label>
                        {
                            <Form.Select onChange={onChange} 
                                name="imageType" aria-label="imageType" 
                                value={manualDetection?.imageType || ""}
                            >
                                <option value={""}>Choose Image Type</option>
                                <option value={"organic"}>Organic</option>
                                <option value={"nonOrganic"}>NonOrganic</option>
                            </Form.Select>
                        }
                    </div>
                    <div className="mb-3">
                        <label className="mb-1 fw-bold">Organization <span className="text-danger">*</span></label>
                        {
                            (isTenantUser() || isAdminUser()) ?
                            <Form.Select onChange={onChange} 
                                name="organizationId" aria-label="organizatioinId" 
                                value={userInfo.orgId}
                                disabled
                            >
                                <option value={userInfo.orgId}>
                                    {userInfo.orgName || "Organization Not Found"}
                                </option>
                            </Form.Select> :
                            <Form.Select onChange={onChange} 
                                name="organizationId" aria-label="organizationId" 
                                value={manualDetection?.organizationId || 0}
                            >
                                <option value={0}>Choose Organization</option>
                                {
                                (organizations && organizations?.length !== 0 && Array.isArray(organizations)) && organizations.map((org:OrganizationsData) => 
                                    <option value={org.id} key={org.id}>{org.organizationName}</option>
                                )
                                }
                            </Form.Select>
                        }
                    </div>
                    <div className="mb-3">
                        <label className="mb-1 fw-bold">Machine <span className="text-danger">*</span></label>
                        {
                            <Form.Select onChange={onChange} 
                                name="machineId" aria-label="machineId" 
                                value={manualDetection?.machineId || 0}
                            >
                                <option value={0}>Choose Machine</option>
                                {
                                (machines && machines?.length !== 0 && Array.isArray(machines)) && machines.map((machine:MachinesData) => 
                                    <option value={machine.id} key={machine.id}>{machine.machineName}</option>
                                )
                                }
                            </Form.Select>
                        }
                    </div>
                    <div className="mb-3">
                        <label className="mb-1 fw-bold">Tenant <span className="text-danger">*</span></label>
                        {
                            <Form.Select onChange={onChange} 
                                name="tenantId" aria-label="tenantId" 
                                value={manualDetection?.tenantId || 0}
                            >
                                <option value={0}>Choose Tenant</option>
                                {
                                (tenants && tenants?.length !== 0 && Array.isArray(tenants)) && tenants.map((tenant:TenantsData) => 
                                    <option value={tenant.id} key={tenant.id}>{tenant.tenantName}</option>
                                )
                                }
                            </Form.Select>
                        }
                    </div>
                    <InputGroup className="mb-3">
                        <InputGroup.Text><span>Weight <span className="text-danger">*</span></span></InputGroup.Text>
                        <Form.Control name="weight" onChange={onChange} type="text" placeholder="Enter Weight" value={manualDetection?.weight || 0} required/>
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Text><span>Remark</span></InputGroup.Text>
                        <Form.Control name="remark" onChange={onChange} type="text" placeholder="Enter Remark" value={manualDetection?.remark || ""} />
                    </InputGroup>
                </ModalContainer>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={toggleShow}>Cancel</Button>
                <Button variant="success" onClick={onAdd}>Add</Button>
            </Modal.Footer>
        </Modal>
    );
}
 
export default AddModal;