import React, { useEffect, useState } from "react";
import { Pie } from 'react-chartjs-2';
import PieChartContainer from './PieChartContainer';
import { ChartData, ChartOptions, ArcElement, Tooltip, Legend, Chart, ChartDataset } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels'; 
import { BarChartWeightResponseType } from "../../../types/NewDashboardType";
import { DateFilterValue } from "../../../types/global/FilterValueType";
import {
  ParentContainer,
} from "./../DashboardElements";
import { wasteProfileOptions } from './../WeightChartOptions';

Chart.register(ArcElement, Tooltip, Legend, ChartDataLabels); 

interface WasteProfilePieChartProps {
  orgId: number,
  locationId: number,
  machineId: number,
  tenantId: number,
  dateFilterValue: DateFilterValue;
  weightResponse: BarChartWeightResponseType;
}

interface CustomChartDataset extends ChartDataset<'pie', number[]> {
  weightList?: number[];
}

const WasteProfilePieChart: React.FC<WasteProfilePieChartProps> = ({ orgId, locationId, machineId, tenantId, dateFilterValue, weightResponse }) => {
  const [totalWeight, setTotalWeight] = useState<number>(0);
  const [weightChartData, setWeightChartData] = useState<ChartData<"pie", number[], unknown>>({
    labels: [],
    datasets: [
      {
        label: 'Total Weight (kg)',
        data: [],
        weightList: [],
        backgroundColor: [
          '#800080',
          '#FFA500',
          '#FF00FF',
          '#9D00FF',
          '#7C4DFF',
          '#651FFF',
          '#6200EA',
        ],
        borderWidth: 1,
      } as CustomChartDataset,
    ],
  });

  useEffect(() => {
    loadPropsDataToStates();
  }, [weightResponse, orgId, locationId, machineId, tenantId, dateFilterValue]);

  const loadPropsDataToStates = () => {
    const totalWeightSum = weightResponse?.weightList?.reduce((sum, item) => {
      return sum + item.totalWeight;
    }, 0);
    setTotalWeight(totalWeightSum)
    const updatedLabels = weightResponse?.weightList?.map(item => item.name);
    const updatedData = weightResponse?.weightList?.map(item => parseFloat((item.totalWeight / totalWeightSum * 100).toFixed(2)));
    const updatedWeightList = weightResponse?.weightList?.map(item => item.totalWeight);
    
    setWeightChartData({
      labels: updatedLabels,
      datasets: [
        {
          ...weightChartData.datasets[0],
          data: updatedData,
          weightList: updatedWeightList
        } as CustomChartDataset,
      ],
    });
  };

  return (
    <ParentContainer>
      <PieChartContainer>
        <Pie data={weightChartData} options={wasteProfileOptions(totalWeight)} className='my-chart' />
      </PieChartContainer>
    </ParentContainer>
  );
};

export default WasteProfilePieChart;
