export const DEFAULT_CARBON_REDUCTION_RATIO = 4.2

export const wasteTypes: Record<string, string> = {
    "": "Choose Waste Type",
    "FOOD_WASTE": "Food Waste",
    "GENERAL_WASTE": "General Waste",
    "E_WASTE": "E-Waste",
    "RECYCLE_WASTE_PAPER": "Recycle Waste (Paper)",
    "RECYCLE_WASTE_PLASTIC": "Recycle Waste (Plastic)",
    "RECYCLE_WASTE_GLASS": "Recycle Waste (Glass)",
    "RECYCLE_WASTE_METAL": "Recycle Waste (Metal)"
};