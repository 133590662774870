import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import Alert from '@mui/material/Alert';
import { UserAuthContext } from "../../contexts/UserAuthContext";
import { useFilterOrganizations } from "../../custom-hooks/useFilterOrganizations";
import { useFilterTenants } from "../../custom-hooks/useFilterTenants";
import useFilterLocations from '../../custom-hooks/useFilterLocations';
import { useFilterMachinesByLocationIds } from '../../custom-hooks/useFilterMachinesByLocationIds';
import { Box, Typography } from "@mui/material";
import CalendarModal from "../Shared/CalendarModal/CalendarModal";
import { OrganizationsData } from "../../types/OrganizationsType";
import { UserAuthType } from "../../types/UserAuth";
import { getCurrentLocalUser, isAdminUser, isSuperSpecialUser, isTenantUser } from "../../utils/UserUtil";
import Layout from "../Layout/Layout";
import Card from "../Shared/Card/Card";
import { Container, FilterContainer } from "../Shared/Common/Containers";
import UnauthorizedPage from "../Shared/ErrorPages/UnauthorizedPage";
import {
  CardContainer, 
  ChartContainer, 
  LargeChartContainer,
  Title,
} from "./DashboardElements";
import WeightBarChart from "./components/WeightBarChart";
import WeightBarChartByWasteType from "./components/WeightBarChartByWasteType";
import WeightBarChartByTenant from "./components/WeightBarChartByTenant";
import WasteProfilePieChart from "./components/WasteProfilePieChart";
import TotalWeightLineChart from "./components/TotalWeightLineChart";
import { getDefaultDateFilterValue } from "../../utils/DefaultFilterValueUtil";
import { DateFilterValue } from "../../types/global/FilterValueType";
import { convertDateFormat } from "../../utils/DateTimeUtil";
import { getWeightApiByWasteTypeApi, getTotalWeightApi } from "../../services/RouteServices/NewDashboardApi";
import { STATUS_CODES } from "../../constants/GlobalConstants";
import { 
  BarChartWeightRequestType, 
  BarChartWeightResponseType, 
  TotalWeightResponseType } from "../../types/NewDashboardType";
import { Badge, Col, Form, InputGroup, Row } from "react-bootstrap";
import LocalDrinkIcon from '@mui/icons-material/LocalDrink';
import ArticleIcon from '@mui/icons-material/Article';
import PlasticIcon from "../../components/Icons/PlasticIcon";
import MetalIcon from "../../components/Icons/MetalIcon";
import '../../styling/customStyle.css';
import { TenantsData } from "../../types/TenantsType";
import { LocationsData } from "../../types/LocationsType";
import { MachinesData } from "../../types/MachinesType";

const NewDashboard: React.FC = () => {
  const user = getCurrentLocalUser()
  const { hasPermission } = useContext(UserAuthContext) as UserAuthType

  const [selectedOrganization, setSelectedOrganization] = useState(
    isAdminUser() ? user.orgId : 0
  )
  const [selectedTenant, setSelectedTenant] = useState(0)
  const [selectedLocation, setSelectedLocation] = useState(0)
  const [selectedMachine, setSelectedMachine] = useState(0)
  const [errorOverallText] = useState("");
  const [showStartDateModal, setShowStartDateModal] = useState(false)
  const [dateFilterValue, setDateFilterValue] = useState<DateFilterValue>(getDefaultDateFilterValue());
  const [showEndDateModal, setShowEndDateModal] = useState(false);
  const [weightByWasteTypeResponse, setWeightByWasteTypeResponse] = useState<BarChartWeightResponseType>({
    weightList: [],
  });
  const [totalWeightResponse, setTotalWeightResponse] = useState<TotalWeightResponseType>({
    eWaste: 0,
    foodWaste: 0,
    generalWaste: 0,
    recycleWaste: {
      glassWaste: 0,
      plasticWaste: 0,
      paperWaste: 0,
      metalWaste: 0,
      totalRecycleWeight: 0,
    },
    totalWeight: 0,
    carbonReduction: 0
  });

  useEffect(() => {
    getWeightByWasteType();
    getTotalWeight();
  }, [dateFilterValue, selectedOrganization, selectedLocation, selectedMachine, selectedTenant]);

  const {organizations, error} = useFilterOrganizations()
  const {locations} = useFilterLocations(selectedOrganization!  || 0, [selectedOrganization]);
  const {machines} = useFilterMachinesByLocationIds([selectedLocation!  || -1], [selectedLocation])
  const {tenants, error: tenantError} = useFilterTenants(selectedMachine || -1, [selectedMachine])

  const handleChangeOrganization = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedOrganization(+event.target.value)
  }

  const handleChangeTenant = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedTenant(+event.target.value)
  }
  const handleChangeLocation = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedLocation(+event.target.value)
  }
  const handleChangeMachine = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedMachine(+event.target.value)
  }

  const handleClickStartDate = () => {
    setShowStartDateModal(!showStartDateModal)
  }

  const handleClickEndDate = () => {
    setShowEndDateModal(!showEndDateModal)
  }

  const handleChangeDateFilterValue = (key: "startDate" | "endDate", value: Date) => {
    const day = value.getDate().toString().padStart(2, '0');
    const month = (value.getMonth() + 1).toString().padStart(2, '0');
    const year = value.getFullYear();
    const formattedDate = `${year}-${month}-${day}`;
    setDateFilterValue({...dateFilterValue, [key]: formattedDate})
  }

  const handleCloseDateModal = () => {
    setShowStartDateModal(false)
    setShowEndDateModal(false)
  }

  const getWeightByWasteType = () => {
    const input : BarChartWeightRequestType  = {
      orgId: selectedOrganization,
      locationId: selectedLocation,
      machineId: selectedMachine,
      tenantId: selectedTenant,
      startDate: convertDateFormat(dateFilterValue?.startDate || ""),
      endDate: convertDateFormat(dateFilterValue?.endDate || ""),
      isForAllOrganization: (selectedOrganization <= 0),
    };

    getWeightApiByWasteTypeApi(input)
      .then(res => {
        if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
          const data: BarChartWeightResponseType = res?.data?.data;
          setWeightByWasteTypeResponse(data);
        }
      })
      .catch(error => {
        console.log(error?.message);
      });
  };

  const getTotalWeight = () => {
    const input : BarChartWeightRequestType  = {
      orgId: selectedOrganization,
      locationId: selectedLocation,
      machineId: selectedMachine,
      tenantId: selectedTenant,
      startDate: convertDateFormat(dateFilterValue?.startDate || ""),
      endDate: convertDateFormat(dateFilterValue?.endDate || ""),
      isForAllOrganization: (selectedOrganization <= 0)
    };

    getTotalWeightApi(input)
      .then(res => {
        if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
          const data: TotalWeightResponseType = res?.data?.data;
          setTotalWeightResponse(data);
        }
      })
      .catch(error => {
        console.log(error?.message);
      });
  };

  if (!hasPermission("viewNewDashboard")) {
    return (
      <Layout>
          <Container>
            <UnauthorizedPage />
          </Container>
      </Layout>
    )
  }

  return (
    <>
      <Layout>
        <Container>
          {errorOverallText && <Alert severity="error" sx={{marginBottom: 2}}>{errorOverallText}</Alert>}
          <CardContainer>
              <Card backgroundColor="#4CCD99">
                <p>Total Weight {totalWeightResponse?.totalWeight > 1000 ? '(Tonne)':'(KG)'}</p>
                <h3>{totalWeightResponse?.totalWeight > 1000 ? (totalWeightResponse?.totalWeight/1000).toFixed(2) : totalWeightResponse?.totalWeight?.toFixed(2) || 0}</h3>
              </Card>
              <Card  backgroundColor="#008000">
                <p>Carbon Reduction {totalWeightResponse?.carbonReduction > 1000 ? '(Tonne)':'(KG)'}</p>
                <h3>{totalWeightResponse?.carbonReduction > 1000 ? (totalWeightResponse?.carbonReduction/1000).toFixed(2) : totalWeightResponse?.carbonReduction?.toFixed(2) || 0}</h3>
              </Card>
              <Card  backgroundColor="#800080">
                <p>Food Waste {totalWeightResponse?.foodWaste > 1000 ? '(Tonne)':'(KG)'}</p>
                <h3>{totalWeightResponse?.foodWaste > 1000 ? (totalWeightResponse?.foodWaste/1000).toFixed(2) : totalWeightResponse?.foodWaste?.toFixed(2) || 0}</h3>
              </Card>
              <Card  backgroundColor="#FFA500">
                <p>General Waste {totalWeightResponse?.generalWaste > 1000 ? '(Tonne)':'(KG)'}</p>
                <h3>{totalWeightResponse?.generalWaste > 1000 ? (totalWeightResponse?.generalWaste/1000).toFixed(2) : totalWeightResponse?.generalWaste?.toFixed(2) || 0}</h3>
              </Card>
              <Card  backgroundColor="#FF00FF">
                <p>E-Waste {totalWeightResponse?.eWaste > 1000 ? '(Tonne)':'(KG)'}</p>
                <h3>{totalWeightResponse?.eWaste > 1000 ? (totalWeightResponse?.eWaste/1000).toFixed(2) : totalWeightResponse?.eWaste?.toFixed(2) || 0}</h3>
              </Card>
              <Card  backgroundColor="#9D00FF">
                <p>Recycle Waste {totalWeightResponse?.recycleWaste?.totalRecycleWeight > 1000 ? '(Tonne)':'(KG)'}</p>
                <h3>{totalWeightResponse?.recycleWaste?.totalRecycleWeight > 1000 ? (totalWeightResponse?.recycleWaste?.totalRecycleWeight/1000).toFixed(2) : totalWeightResponse?.recycleWaste?.totalRecycleWeight?.toFixed(2) || 0}</h3>
                <Box sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: 3
                }}>
                  <div>
                    <Badge bg="light" text="dark" className="fs-6">
                      <div style={{alignItems: "center", display: "flex", gap: 4}}>
                        <LocalDrinkIcon className="text-primary fs-4"/>
                        {totalWeightResponse?.recycleWaste?.totalRecycleWeight > 1000 ? 
                        (totalWeightResponse?.recycleWaste?.totalRecycleWeight/1000).toFixed(2) 
                        : totalWeightResponse?.recycleWaste?.glassWaste?.toFixed(2) || 0} {totalWeightResponse?.recycleWaste?.totalRecycleWeight > 1000 ? '(Tonne)': '(KG)'}
                      </div>
                    </Badge>
                    Glass
                  </div>
                  <div>
                    <Badge bg="light" text="dark" className="fs-6">
                      <div style={{alignItems: "center", display: "flex", gap: 4}}>
                        <ArticleIcon className="text-primary fs-3"/>
                        {totalWeightResponse?.recycleWaste?.paperWaste > 1000 ? 
                        (totalWeightResponse?.recycleWaste?.paperWaste/1000).toFixed(2) 
                        : totalWeightResponse?.recycleWaste?.paperWaste?.toFixed(2) || 0} {totalWeightResponse?.recycleWaste?.paperWaste > 1000 ? '(Tonne)': '(KG)'}
                      </div>
                    </Badge>
                    Paper
                  </div>
                  <div>
                    <Badge bg="light" text="dark" className="fs-6">
                      <div style={{alignItems: "center", display: "flex", gap: 4}}>
                        <PlasticIcon/>
                        {totalWeightResponse?.recycleWaste?.plasticWaste > 1000 ? 
                        (totalWeightResponse?.recycleWaste?.plasticWaste/1000).toFixed(2) 
                        : totalWeightResponse?.recycleWaste?.plasticWaste?.toFixed(2) || 0} {totalWeightResponse?.recycleWaste?.plasticWaste > 1000 ? '(Tonne)': '(KG)'}
                      </div>
                    </Badge>
                    Plastic
                  </div>
                  <div>
                    <Badge bg="light" text="dark" className="fs-6">
                      <div style={{alignItems: "center", display: "flex", gap: 4}}>
                        <MetalIcon/>
                        {totalWeightResponse?.recycleWaste?.metalWaste > 1000 ? 
                        (totalWeightResponse?.recycleWaste?.metalWaste/1000).toFixed(2) 
                        : totalWeightResponse?.recycleWaste?.metalWaste?.toFixed(2) || 0} {totalWeightResponse?.recycleWaste?.metalWaste > 1000 ? '(Tonne)': '(KG)'}
                      </div>
                    </Badge>
                    Metal
                  </div>
                </Box>
              </Card>
          </CardContainer>
          <FilterContainer>
            <Form.Group>
              <Row>
                <Col xs={12} md={6} lg={4}>
                  <InputGroup className="mb-3">
                    <InputGroup.Text className="input-user"><span>Organization</span></InputGroup.Text>
                      {
                        isSuperSpecialUser() ?
                        <Form.Select onChange={handleChangeOrganization} 
                          name="organizationId" aria-label="organizatioinId" 
                          value={selectedOrganization}
                        >
                              <option value={0}>All</option>
                              {
                                (organizations && organizations?.length !== 0) && organizations.map((org:OrganizationsData) => 
                                    <option value={org.id} key={org.id}>{org.organizationName}</option>
                                )
                              }
                        </Form.Select> :
                        <Form.Select
                          value={user.orgId}
                          disabled
                        >
                            <option value={user.orgId}>
                                {organizations.filter((org:OrganizationsData) => org.id === +user.orgId)[0]?.organizationName}
                            </option>
                        </Form.Select>
                      }
                  </InputGroup>
                </Col>
                <Col xs={12} md={6} lg={4}>
                  <InputGroup>
                      <InputGroup.Text className="input-user"><span>Location</span></InputGroup.Text>
                      {
                          <Form.Select onChange={handleChangeLocation} 
                          name="locationId" aria-label="locationId" 
                          value={selectedLocation}
                          disabled={!Boolean(selectedOrganization && ""+selectedOrganization !== '0')}
                          >
                            <option value={0}>All</option>
                            {
                            (locations && locations?.length !== 0) && locations.map((location: LocationsData) => 
                                <option value={location.id} key={location.id}>{location.locationName}</option>
                            )
                            }
                          </Form.Select>
                      }
                  </InputGroup>
                </Col>
                <Col xs={12} md={6} lg={4}>
                  <InputGroup>
                    <InputGroup.Text className="input-user"><span>Machine</span></InputGroup.Text>
                    {
                        <Form.Select onChange={handleChangeMachine} 
                        name="machineId" aria-label="machineId" 
                        value={selectedMachine}
                        disabled={
                            !Boolean(selectedLocation && ""+selectedLocation !== '0') && !isTenantUser()
                        }
                        >
                            <option value={0}>All</option>
                            {
                            (machines && machines?.length !== 0) && machines.map((machine:MachinesData) => 
                                <option value={machine.id} key={machine.id}>{machine.machineName}</option>
                            )
                            }
                        </Form.Select>
                    }
                  </InputGroup>
                </Col>
                <Col xs={12} md={6} lg={4}>
                  <InputGroup>
                      {
                        (!isTenantUser()) &&
                        <>
                        <InputGroup.Text className="input-user"><span>Tenant</span></InputGroup.Text>
                            <Form.Select 
                              onChange={handleChangeTenant} 
                              name="tenantId" aria-label="tenantId" 
                              value={selectedTenant}
                              disabled={!Boolean(selectedMachine && selectedMachine !== 0)}
                            >
                              <option value={0}>All</option>
                              {
                              (tenants && tenants?.length !== 0) && tenants.map((tenant:TenantsData) => 
                                  <option value={tenant.id} key={tenant.id}>{tenant.tenantName}</option>
                              )
                              }
                        </Form.Select>
                        </>
                      }
                    </InputGroup>
                </Col>
                <Col xs={12} md={6} lg={2}>
                  <Box 
                    sx={(theme) => ({
                        display: "flex", 
                        gap: 1, 
                        alignItems: "center",
                        [theme.breakpoints.down('sm')]: {
                            flexDirection: "column",
                            justifyContent: "center",
                        },
                    })}
                  >
                    <Typography>Start Date</Typography>
                    <button type="button" className="btn btn-outline-dark" onClick={handleClickStartDate}>{dateFilterValue?.startDate || "Start Date"}</button>
                    <CalendarModal 
                        show={showStartDateModal}
                        onCloseModal={handleClickStartDate}
                        onChange={(value: Date) => {
                          handleChangeDateFilterValue("startDate", value)
                            handleCloseDateModal()
                        }}
                        maxDate={new Date(dateFilterValue.endDate)}
                        defaultValue={new Date(dateFilterValue.startDate)}
                    />
                  </Box>
                </Col>
                <Col xs={12} md={6} lg={2}>
                  <Box 
                    sx={(theme) => ({
                        display: "flex", 
                        gap: 1, 
                        alignItems: "center",
                        [theme.breakpoints.down('sm')]: {
                            flexDirection: "column",
                            justifyContent: "center",
                        },
                    })}
                  > 
                    <Typography>End Date</Typography>
                    <button type="button" className="btn btn-outline-dark" onClick={handleClickEndDate}>{dateFilterValue?.endDate || "End Date"}</button>
                    <CalendarModal 
                        show={showEndDateModal}
                        onCloseModal={handleClickEndDate}
                        onChange={(value: Date) => {
                          handleChangeDateFilterValue("endDate", value)
                          handleCloseDateModal()
                        }}
                        maxDate={new Date()}
                        minDate={new Date(dateFilterValue.startDate)}
                        defaultValue={new Date(dateFilterValue.endDate)}
                    />
                  </Box>
                </Col>
              </Row>
            </Form.Group>
          </FilterContainer>
          <ChartContainer>
            <div>
              <Title>Top Contributed Tenant</Title>
              <WeightBarChartByTenant 
               orgId={selectedOrganization}
               locationId={selectedLocation}
               machineId={selectedMachine}
               tenantId={selectedTenant}
               dateFilterValue={dateFilterValue}
               barItemColor="#0000FF"
               apiUrl="top-tenant"/>
            </div>
            <div>
              <Title>Waste Profile</Title>
              <WasteProfilePieChart 
                orgId={selectedOrganization}
                locationId={selectedLocation}
                machineId={selectedMachine}
                tenantId={selectedTenant}
                dateFilterValue={dateFilterValue}
                weightResponse={weightByWasteTypeResponse}
              />
            </div>
          </ChartContainer>
          <ChartContainer>
            <div>
              <Title>Total Weight by Location</Title>
              <WeightBarChart 
               orgId={selectedOrganization}
               locationId={selectedLocation}
               machineId={selectedMachine}
               tenantId={selectedTenant}
               dateFilterValue={dateFilterValue}
               barItemColor="#0000FF"
               apiUrl="location"/>
            </div>
            <div>
              <Title>Waste Weight by Waste Type</Title>
              <WeightBarChartByWasteType
                orgId={selectedOrganization}
                dateFilterValue={dateFilterValue}
                barItemColor="#0000FF"
                weightResponse={weightByWasteTypeResponse}
              />
            </div>
          </ChartContainer>
          <LargeChartContainer>
              <Title>Total Weight by Machine</Title>
            <WeightBarChart 
              orgId={selectedOrganization}
              locationId={selectedLocation}
              machineId={selectedMachine}
              tenantId={selectedTenant}
              dateFilterValue={dateFilterValue}
              barItemColor="#0000FF"
              apiUrl="machine"/>
          </LargeChartContainer> 
          <LargeChartContainer>
            <TotalWeightLineChart 
              orgId={selectedOrganization} 
              locationId={selectedLocation}
              machineId={selectedMachine}
              tenantId={selectedTenant}
            />
          </LargeChartContainer> 
        </Container>
      </Layout>
    </>
  );
};

export default NewDashboard;
