import React, { useEffect, useState } from "react";
import { Bar } from 'react-chartjs-2';
import { ChartData } from 'chart.js';
import Pagination from '@mui/material/Pagination';
import { BarChartWeightResponseType } from "../../../types/NewDashboardType";
import { weightOptions } from "../WeightChartOptions";
import { DateFilterValue } from "../../../types/global/FilterValueType";
import {
  ParentContainer,
} from "../DashboardElements";
import ChartContainer from './ChartContainer';

interface WeightBarChartProps {
  orgId: number;
  dateFilterValue: DateFilterValue;
  barItemColor: string;
  weightResponse: BarChartWeightResponseType;
}

const WeightBarChartByWasteType: React.FC<WeightBarChartProps> = ({ orgId, dateFilterValue, barItemColor, weightResponse }) => {
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [labels, setLabels] = useState<string[]>([]);
  const [weights, setWeights] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isChartData, setIsChartData] = useState<boolean>(false);
  const [barChartItemsPerPage, setBarChartItemsPerPage] = useState(10);
  const [dynamicBarPercentage, setDynamicBarPercentage] = useState(1);
  const [weightChartData, setWeightChartData] = useState<ChartData<"bar", number[], unknown>>({
    labels: [],
    datasets: [
      {
        label: 'Total Weight (kg)',
        data: [],
        backgroundColor: barItemColor,
      },
    ],
  });

  useEffect(() => {
    loadDataToStates(weightResponse);
  }, [weightResponse, orgId, dateFilterValue]);

  useEffect(() => {
    if (labels.length > 0 && weights.length > 0) {
      updateChartData(page);
    }
  }, [labels, weights, page]);

  const updateChartData = (pageNumber: number) => {
    const startIndex = (pageNumber - 1) * barChartItemsPerPage;
    const endIndex = pageNumber * barChartItemsPerPage;

    const visibleLabels = labels.slice(startIndex, endIndex);

    setWeightChartData({
      labels: visibleLabels,
      datasets: [
        {
          ...weightChartData.datasets[0],
          data: weights.slice(startIndex, endIndex),
        },
      ],
    });

    if (visibleLabels.length < 2) {
      setDynamicBarPercentage(0.2);
    } else if (visibleLabels.length < 5) {
      setDynamicBarPercentage(0.4);
    } else {
      setDynamicBarPercentage(1.5);
    }
  };

  const loadDataToStates = (data: BarChartWeightResponseType) => {
    const updatedLabels = data.weightList.map(item => item.name);
    const updatedData = data.weightList.map(item => item.totalWeight);
    const totalPages = Math.ceil(updatedData.length / barChartItemsPerPage);

    const updatedColors = data.weightList.map(item => {
      return item.deletedAt ? '#686A6C' : '#0000FF';
    });

    setIsChartData(true);
    setLabels(updatedLabels);
    setWeights(updatedData);
    setTotalPage(totalPages);
    setPage(1);

    setWeightChartData({
      labels: updatedLabels,
      datasets: [
        {
          label: 'Total Weight (kg)',
          data: updatedData,
          backgroundColor: updatedColors,
        },
      ],
    });

    if (data?.weightList && data.weightList.length < 2) {
      setDynamicBarPercentage(0.2);
    } else if (data?.weightList && data.weightList.length < 5) {
      setDynamicBarPercentage(0.4);
    } else {
      setDynamicBarPercentage(1.5);
    }
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, pageNumber: number) => {
    setPage(pageNumber);
    updateChartData(pageNumber);
  };

  return (
    <ParentContainer>
      <ChartContainer>
        <Bar options={weightOptions(dynamicBarPercentage)} data={weightChartData} className="my-chart" />
      </ChartContainer>
      {isChartData && (
        <Pagination
          count={totalPage}
          page={page}
          color="primary"
          onChange={handlePageChange}
        />
      )}
    </ParentContainer>
  );
};

export default WeightBarChartByWasteType;
