import { FunctionComponent, useContext } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import ModalImage from 'react-modal-image';
import { convertToLocaleDate } from '../../utils/DateTimeUtil';
import { isSuperSpecialUser } from '../../utils/UserUtil';
import { mergeImageUrlWithBaseUrl } from '../../utils/ImageUtil';
import { NO_IMAGE_URL } from '../../constants/GlobalConstants';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddIcon from '@mui/icons-material/Add';
import { UserAuthContext } from '../../contexts/UserAuthContext';
import { UserAuthType } from '../../types/UserAuth';
import { WasteUploadData } from '../../types/WasteUploadType';
import { wasteTypes } from "../../constants/SystemConstants";

interface WasteUploadCardCardProps {
    wasteUpload: WasteUploadData
    onClickDelete: (wasteUpload: WasteUploadData) => void
    onClickEdit: (wasteUpload: WasteUploadData) => void
}
 
const WasteUploadCard: FunctionComponent<WasteUploadCardCardProps> = ({
    wasteUpload,
    onClickDelete,
    onClickEdit
}) => {
    const { hasPermission } = useContext(UserAuthContext) as UserAuthType

    const getWasteType = (value: string) => {
        return wasteTypes[value] || "-"
    };

    return (  
        <Card sx={{ width: 350, marginX: 3, borderRadius: 3, overflow: "hidden" }} className='capture-card'>
            <CardMedia
                sx={{ height: 250, overflow: "hidden" }}
            >
                <ModalImage
                    small={mergeImageUrlWithBaseUrl(wasteUpload?.imagePath) || NO_IMAGE_URL}
                    large={mergeImageUrlWithBaseUrl(wasteUpload?.imagePath) || NO_IMAGE_URL}
                    alt="Image"
                    className="card-img"
                />
            </CardMedia>
            <CardContent sx={{ maxHeight: 400, overflow: "scroll" }} className="hide-scroll-bar">
                <div className='d-flex justify-content-end align-items-center gap-2 mb-3'>
                    {
                        hasPermission("editWasteUpload") &&
                        <EditOutlinedIcon 
                            sx={{
                                color: "blue",
                                fontSize: "30px"
                            }}
                            className="pointer"
                            onClick={() => onClickEdit(wasteUpload)}
                        />
                    }
                    {
                        hasPermission("deleteWasteUpload") &&
                        <DeleteOutlinedIcon 
                            sx={{
                                color: "red",
                                fontSize: "30px"
                            }}
                            className="pointer"
                            onClick={() => onClickDelete(wasteUpload)}
                        />
                    }
                </div>
                <div className='d-flex justify-content-between align-items-center'>
                    <h4><span className="badge bg-info">Id: {wasteUpload.id}</span></h4>
                    <span className="badge" style={{backgroundColor: "#9BCF53"}}>{getWasteType(wasteUpload.wasteType)}</span> 
                </div>
                <table>
                    <tbody>
                        <tr>
                            <td className='pe-2 py-2'>Weight(kg)</td>
                            <td className='ps-3 border-start text-secondary'>{wasteUpload.weight || 0}</td>
                        </tr>
                        <tr>
                            <td className='pe-2 py-2'>Capture Time</td>
                            <td className='ps-3 border-start text-secondary'>{convertToLocaleDate(wasteUpload.captureTime) || "-"}</td>
                        </tr>
                        <tr>
                            <td className='pe-3 py-2'>Organization</td>
                            <td className='ps-3 border-start text-secondary'>{wasteUpload.organizationName || "-"}</td>
                        </tr>
                        <tr>
                            <td className='pe-3 py-2'>Tenant</td>
                            <td className='ps-3 border-start text-secondary'>{wasteUpload.tenantName || "-"}</td>
                        </tr>
                        <tr>
                            <td className='pe-3 py-2'>Machine</td>
                            <td className='ps-3 border-start text-secondary'>{wasteUpload.machineName || "-"}</td>
                        </tr>
                        <tr>
                            <td className='pe-3 py-2'>Remark</td>
                            <td className='ps-3 border-start text-secondary remark-container hide-scroll-bar'>{wasteUpload.remark || "-"}</td>
                        </tr>
                        {
                            isSuperSpecialUser() &&
                            <tr>
                                <td className='pe-3'>Created At</td>
                                <td className='ps-3 border-start text-secondary'>{convertToLocaleDate(wasteUpload.createdAt!) || "-"}</td>
                            </tr>
                        }
                        <tr>
                            <td className='pe-3 py-2'>Created By</td>
                            <td className='ps-3 border-start text-secondary remark-container hide-scroll-bar'>{wasteUpload.createdBy || "-"}</td>
                        </tr>
                    </tbody>
                </table>
            </CardContent>
        </Card>
    );
}
 
export default WasteUploadCard;