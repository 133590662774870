export type DetectionData = {
    id?: number,
    organizationId: number,
    tenantId: number,
    machineId: number,
    captureTime: string,
    weight: number,
    captureImage: {image: "", captureTime: ""},
    organicImgPath: string,
    nonOrganicImgPath: string,
    organicImageUrl: string,
    nonOrganicImageUrl: string,
    originalImgPath: string,
    isOrganic: string,
    isNonOrganic: string,
    organicDetectionList: string,
    nonOrganicDetectionList: string,
    syncAlready: string,
    remark?: string,
    createdBy?: string;
    updatedBy?: string;
    deletedBy?: string;
    createdAt?: string;
    updatedAt?: string;
    deletedAt?: string;
    organizationName?: string;
    tenantName?: string;
    machineName?: string;
}

export type FirstCaptureImage = {
    [key: number]: {imageUrl: string, captureTime: string}
}

export type DetectionListResponseBody = {
    data: {
      detectionList: DetectionData[],
      totalCount: number,
      totalWeight?: number,
      staticLocations: string[]
    }
    status: number,
    message: string,
}

export type DetectionResponseBody = {
    data: DetectionData,
    status: number,
    message: string,
}
  
export type DetectionGETRequestBody = {
    orgId?: number,
    userId?: number,
    machineId?: number,
    tenantId?: number,
    startDate?: string,
    endDate?: string,
    limit?: number,
    offset?: number,
    showManualUpload?: boolean,
    includeNonDetections?: boolean,
}

export type DetectionDELETERequestBody = {
    id: number,
    deletedBy: string
}

export type DetectionUPDATERequestBody = {
    id?: number,
    tenantId: number,
    machineId: number,
    captureTime: string,
    imgPath: string,
    syncAlready: string,
    remark?: string,
    updatedBy?: string;
    updatedAt?: string;
}

export type DetectionADDRequestBody = {
    machineId: number,
    createdBy: string,
}

export type MachineDetectionData = {
    machineId: number,
    detectionList: DetectionData[]
}

export type MachineDetectionGETListResponseBody = {
    data: MachineDetectionData,
    status: number,
    message: string
}

export type ImageData = {
    imgPath: string,
    captureTime: string,
}
  
export const DetectionDefault: DetectionData = {
    id: 0,
    organizationId: 0,
    tenantId: 0,
    machineId: 0,
    weight: 0,
    captureTime: "",
    captureImage: {image: "", captureTime: ""},
    organicImgPath: "",
    nonOrganicImgPath: "",
    originalImgPath: "",
    organicImageUrl: "",
    nonOrganicImageUrl: "",
    isOrganic: "",
    isNonOrganic: "",
    organicDetectionList: "",
    nonOrganicDetectionList: "",
    syncAlready: "",
    remark: "",
}
