import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import '../../styling/customStyle.css';
import Layout from "../Layout/Layout";

import { LOG_LEVEL, PAGE_LIMIT, STATUS_CODES } from "../../constants/GlobalConstants";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import { CAPTURE_ADD_MINUTE_FOR_END_TIME, CAPTURE_ADJUST_TIME_FOR_WEIGHT_LOG_IN_SECOND } from "../../constants/LfcContants";
import { UserAuthContext } from "../../contexts/UserAuthContext";
import useCaptureImages from "../../custom-hooks/useCaptureImages";
import useDetectionImages from "../../custom-hooks/useDetectionImages";
import { weightLogGetExportListByOrgId, weightLogGetExportListByUserId, weightLogGetListByOrgIdApi, weightLogGetListByUserIdApi } from "../../services/RouteServices/WeightLogApi";
import { UserAuthType, UserInfo } from "../../types/UserAuth";
import { WeightLogData, WeightLogDataDefault, WeightLogExportListResponseBody, WeightLogListResponseBody } from "../../types/WeightLogType";
import { DateFilterValue, FilterValue } from "../../types/global/FilterValueType";
import { addMinuteToDate, addSecondToDate, convertDateFormat, convertToLocaleDate, getCurrentLocaleDate, getDayDifference } from '../../utils/DateTimeUtil';
import { getDefaultDateFilterValue, getDefaultFilterValue } from "../../utils/DefaultFilterValueUtil";
import { addEventLog } from "../../utils/EventLogUtil";
import { addNormalLog } from "../../utils/LoggerUtil";
import { checkLocation, getAvailableLocations, getCurrentLocalUser, isSpecialUser, isSuperSpecialUser, isTenantUser } from "../../utils/UserUtil";
import { Container, InnterTableContainer, PaginationContainer, TableContainer } from "../Shared/Common/Containers";
import { SummaryText, Title } from "../Shared/Common/Titles";
import UnauthorizedPage from "../Shared/ErrorPages/UnauthorizedPage";
import ExportButton from "../Shared/Export/Export";
import LoadingModal from "../Shared/LoadingModal/LoadingModal";
import ImageModal from "./ImageModal";
import WeightLogFilterContainer from "./WeightLogFilterContainer";
import { generatePDF, generateCSV } from '../../utils/ExportUtil';
 
const WeightLog: React.FC = () => {
    const tableLimit = PAGE_LIMIT;
    const userInfo: UserInfo = getCurrentLocalUser();
    const availableLocations = getAvailableLocations()
    const { hasPermission } = useContext(UserAuthContext) as UserAuthType

    const [errorText, setErrorText] = useState("");
    const [successText, setSuccessText] = useState("");

    const [tablePage, setTablePage] = React.useState(1);
    const [tableOffset, setTableOffset] = React.useState(0);
    const [pageCount, setPageCount] = React.useState(0);
    const [weightLogTotalCount, setWeightLogTotalCount] = React.useState(0);
    const [totalWeightAmount, setTotalWeightAmount] = React.useState(0);
    
    const [weightLogList, setWeightLogList] = useState<WeightLogData[]>([]); // * Original data object from db
    const [weightLogData, setWeightLogData] = useState<WeightLogData>(WeightLogDataDefault);

    const [filterValue, setFilterValue] = useState<FilterValue>(getDefaultFilterValue());
    const [dateFilterValue, setDateFilterValue] = useState<DateFilterValue>(getDefaultDateFilterValue())

    const [showCaptureModal, setShowCaptureModal] = useState<boolean>(false)
    const [showOrganicModal, setShowOrganicModal] = useState<boolean>(false)
    const [showNonOrganicModal, setShowNonOrganicModal] = useState<boolean>(false)
    const [isPreparing, setIsPreparing] = useState<boolean>(false)

    // for fetching capture images
    const startDate = addSecondToDate(convertToLocaleDate(weightLogData?.lfcTime!)!, CAPTURE_ADJUST_TIME_FOR_WEIGHT_LOG_IN_SECOND)?.toString()!
    const endDate = addMinuteToDate(weightLogData?.lfcTime, CAPTURE_ADD_MINUTE_FOR_END_TIME)?.toString()!
    const captureDataLimitObj = {
        machineId: weightLogData?.machineId!,
        startDate: startDate,
        endDate: endDate
    }
    
    const {images: captureImages, totalCount: captureCount} = useCaptureImages(captureDataLimitObj, [showCaptureModal])
    const {organicImages, nonOrganicImages, error: detectionImageError} = useDetectionImages(captureDataLimitObj, [showOrganicModal, showNonOrganicModal])

    const dayRange = getDayDifference(dateFilterValue.startDate, dateFilterValue.endDate);

    useEffect(() => {
        setTablePage(1)
        setTableOffset(0)
    }, [filterValue, dateFilterValue])

    useEffect(() => {
        setErrorText("")
        setSuccessText("")

        if (hasPermission("viewWeightLog")) {
            if(isTenantUser()) {
                getWeightLogDataByUserId()
            } else {
                getWeightLogDataByOrgId();
            }
        }

    }, [filterValue, dateFilterValue, tableOffset])

    useEffect(() => {
        if (tablePage > pageCount && pageCount !== 0) {
            setTablePage(pageCount)
            const offsetValue = (tableLimit * (pageCount - 1));
            setTableOffset(offsetValue);
        }
    }, [pageCount])

    useEffect(() => {
        setFilterValue((prev) => ({
            ...prev,
            locationId: 0,
            machineId: 0,
            tenantId: 0
        }))
    }, [filterValue.organizationId])

    useEffect(() => {
        setFilterValue((prev) => ({
            ...prev,
            machineId: 0,
            tenantId: 0
        }))
    }, [filterValue.locationId])

    useEffect(() => {
        setFilterValue((prev) => ({
            ...prev,
            tenantId: 0
        }))
    }, [filterValue.machineId])

    const exportToCSV = async () => { 
        let exportedData = await getExportedData()
        const headerToKeyMap: { [key: string]: string } = {
            "Id": "id",
            "Digestor Time": "lfcTime",
            "Organization": "organizationName",
            "Location": "locationName",
            "Machine": "machineName",
            "Tenant": "tenantName",
            "Food Waste Weight(kg)": "lfcWeight",
            "Access Card": "lfcCard",
            "Remark": "remark",
          };
        const dateTimeColumns = ["lfcTime"];
        generateCSV(headerToKeyMap, exportedData, dateTimeColumns, "Overall", "weightlogs", userInfo.userName)
    };

    const exportToPdf = async () => {
        let exportedData = await getExportedData()
        const headers = [
            "Id", "Digestor Time", "Organization", "Machine", "Tenant",
            "Location","Food Waste Weight(kg)","Access Card","Remark",
        ];
        const dataKeys = [
            "id", "lfcTime", "organizationName", "machineName", "tenantName",
            "locationName","lfcWeight","lfcCard","remark"
        ];
        const dateTimeColumns = ["lfcTime"];
        const columnWidths = [15, 30, 35, 35, 35, 35, 25, 25, 50];
        generatePDF(headers, dataKeys, columnWidths, exportedData, dateTimeColumns, totalWeightAmount, "Total Weight Amount", "Weight Log", "weightlogs", userInfo.userName, 9, false)
    }

    const exportToPdfWithImage = async () => {
        setIsPreparing(true)
        let exportedData = await getExportedData()
        const dataList = exportedData ? exportedData.map((data: WeightLogData) => ({...data, lfcTime: convertToLocaleDate(data.lfcTime)})) : []
        // generatePdfWithImage(dataList, totalWeightAmount)
        setIsPreparing(false)
        const log: string = `${userInfo.userName} has downloaded weightlogs-with-images-${getCurrentLocaleDate()}.pdf`
        addEventLog(
            log,
            "",
            LOG_LEVEL.INFO
        )
        addNormalLog(log, LOG_LEVEL.INFO)
    }

    const openCaptureModal = (weightLog: WeightLogData) => {
        setShowCaptureModal(true)
        setWeightLogData(weightLog)
    }

    const openOrganicModal = (weightLog: WeightLogData) => {
        setShowOrganicModal(true)
        setWeightLogData(weightLog)
    }

    const openNonOrganicModal = (weightLog: WeightLogData) => {
        setShowNonOrganicModal(true)
        setWeightLogData(weightLog)
    }

    const handleChangeFilterValue = (event: ChangeEvent<HTMLSelectElement>) => {
        setFilterValue(
            (prevValue) => {
                return {
                    ...prevValue,
                    [event.target.name]: event.target.value,
                }
            }
        )
    }

    const handleChangeDateFilterValue = (key: "startDate" | "endDate", value: Date) => {
        const day = value.getDate().toString().padStart(2, '0');
        const month = (value.getMonth() + 1).toString().padStart(2, '0');
        const year = value.getFullYear();
        const formattedDate = `${year}-${month}-${day}`;
        setDateFilterValue({...dateFilterValue, [key]: formattedDate})
    }

    const handleCloseModal = () => {
        setShowCaptureModal(false)
        setShowOrganicModal(false)
        setShowNonOrganicModal(false)
        setWeightLogData(WeightLogDataDefault)
    }

    const getExportedData = async() => {
        let exportedData = []
        try {
            if (isTenantUser()) {
                exportedData = await getExportWeightLogDataByUserId()
            } else {
                exportedData = await getExportWeightLogDataByOrgId()
            }
        } catch(e) {
            exportedData = []
        }
        return exportedData
    }

    const getWeightLogDataByOrgId = () => {
        const dataLimitsObject = {
            orgId: filterValue.organizationId,
            locationId: filterValue.locationId,
            machineId: filterValue.machineId,
            tenantId: filterValue.tenantId,
            availableLocationIds: availableLocations,
            checkLocation: checkLocation(),
            startDate: convertDateFormat(dateFilterValue?.startDate || ""),
            endDate: convertDateFormat(dateFilterValue?.endDate || ""),
            limit: tableLimit,
            offset: tableOffset
        }
        
        weightLogGetListByOrgIdApi(dataLimitsObject)
        .then(response => {
            const resData: WeightLogListResponseBody = response?.data
            if (resData?.status === STATUS_CODES.SUCCESS_CODE) {
                const dataList: WeightLogData[] = resData.data?.weightLogList
                const totalCount : number = resData?.data?.totalCount;
                const totalWeightAmount : number = resData?.data?.totalWeightAmount;
                setWeightLogTotalCount(totalCount);
                setTotalWeightAmount(totalWeightAmount);
                setPageCount((Math.ceil(totalCount/tableLimit)) || 0);
                setSuccessText(resData?.message)
                setWeightLogList(dataList);
                setTimeout(() => {
                    setSuccessText("");
                }, 2000);
            }
            else {
                setErrorText(resData?.message)
                console.log ('error');
            }
        })
        .catch(error => {
            setSuccessText("");
            setErrorText(error?.response?.data?.message || error?.message)
            console.log(error)
        })
    }   

    const getExportWeightLogDataByOrgId = async (): Promise<any> => {
        const dataLimitsObject = {
            orgId: isSuperSpecialUser()? filterValue.organizationId! : userInfo.orgId!,
            locationId: filterValue.locationId,
            machineId: filterValue.machineId || 0,
            tenantId: filterValue.tenantId || 0,
            availableLocationIds: availableLocations,
            checkLocation: checkLocation(),
            startDate: convertDateFormat(dateFilterValue?.startDate || ""),
            endDate: convertDateFormat(dateFilterValue?.endDate || ""),
        }

        return weightLogGetExportListByOrgId(dataLimitsObject)
        .then(res => {
            const resApiData : WeightLogExportListResponseBody = res?.data;
            return new Promise((resolve, reject) => {
                if (res?.data?.status === STATUS_CODES.SUCCESS_CODE)
                    resolve(resApiData.data)
                else 
                    reject([])
            })   
        }).catch(error => {
            Promise.reject([])
        })
    }
    
    const getExportWeightLogDataByUserId = async (): Promise<any> => {
        const dataLimitsObject = {
            machineId: filterValue.machineId || 0,
            userId: userInfo.id || 0,
            startDate: convertDateFormat(dateFilterValue?.startDate || ""),
            endDate: convertDateFormat(dateFilterValue?.endDate || ""),
        }

        return weightLogGetExportListByUserId(dataLimitsObject)
        .then(res => {
            const resApiData : WeightLogExportListResponseBody = res?.data;
            return new Promise((resolve, reject) => {
                if (res?.data?.status === STATUS_CODES.SUCCESS_CODE)
                    resolve(resApiData.data)
                else 
                    reject([])
            })   
        }).catch(error => {
            Promise.reject([])
        })
    }

    const getWeightLogDataByUserId = () => {
        const dataLimitsObject = {
            userId: userInfo.id,
            machineId: filterValue.machineId,
            startDate: convertDateFormat(dateFilterValue?.startDate || ""),
            endDate: convertDateFormat(dateFilterValue?.endDate || ""),
            limit: tableLimit,
            offset: tableOffset
        }
        
        weightLogGetListByUserIdApi(dataLimitsObject)
        .then(response => {
            const resData: WeightLogListResponseBody = response?.data
            if (resData?.status === STATUS_CODES.SUCCESS_CODE) {
                const dataList: WeightLogData[] = resData.data?.weightLogList
                const totalCount : number = resData?.data?.totalCount;
                const totalWeightAmount : number = resData?.data?.totalWeightAmount;
                setWeightLogTotalCount(totalCount);
                setTotalWeightAmount(totalWeightAmount);
                setPageCount((Math.ceil(totalCount/tableLimit)) || 0);
                setSuccessText(resData?.message)
                setWeightLogList(dataList);
                setTimeout(() => {
                    setSuccessText("");
                }, 2000);
            }
            else {
                setErrorText(resData?.message)
                console.log ('error');
            }
        })
        .catch(error => {
            setSuccessText("");
            setErrorText(error?.response?.data?.message || error?.message)
            console.log(error)
        })
    }

    const handleChangePage = (event : ChangeEvent<unknown>, pageValue : number) => {
        setTablePage(pageValue);
        if (pageValue === 1) {
            setTableOffset(0);
        }
        else{
            const offsetValue = (tableLimit * (pageValue - 1));
            setTableOffset(offsetValue);
        }
    };
    // * Render Table Data
    const renderTable = () => {
        let weightLogs = weightLogList || []
        
        return (
            weightLogs?.length !== 0 ? weightLogs?.map((weightLog: WeightLogData) =>          
                    <tr key={weightLog.id}>
                        <td>{weightLog.id}</td>
                        <td>{convertToLocaleDate(weightLog.lfcTime!) || "-"}</td>
                        { isSuperSpecialUser() &&
                            <>
                            <td>{weightLog.organizationName || "-"}</td>
                            <td>{weightLog.locationName || "-"}</td>
                            </>
                        }
                        <td>{weightLog.machineName || "-"}</td>
                        <td>{weightLog.tenantName || "-"}</td>
                        <td>{weightLog.lfcWeight +" kg" || "-"}</td>
                        <td>{weightLog.lfcCard || "-"}</td>
                        {
                            weightLog.syncAlready === 1 ?
                            <td>
                                <span
                                    className={`p-2 rounded text-light ${weightLog.cardId === 1 ? "bg-danger" : weightLog.tenantId ? "bg-success" : "bg-warning"}`}
                                >
                                    synced
                                </span> 
                            </td> :
                            <td></td>
                        }
                        <td>
                            <button 
                                type="button" 
                                className="btn btn-outline-primary btn-sm"
                                onClick={() => openCaptureModal(weightLog)}
                            >
                                Capture
                            </button>
                            {/* &nbsp;&nbsp;
                            <button 
                                type="button" 
                                className="btn btn-outline-primary btn-sm"
                                onClick={() => openOrganicModal(weightLog)}
                            >
                                Organic
                            </button> */}
                            {/* &nbsp;&nbsp;
                            <button 
                                type="button" 
                                className="btn btn-outline-primary btn-sm"
                                onClick={() => openNonOrganicModal(weightLog)}
                            >
                                Non-organic
                            </button> */}
                        </td>
                        <td>{weightLog.remark || "-"}</td>
                    </tr>
            ): 
            <tr>
                <td>No data found</td>
            </tr>
        )
    }

    if (!hasPermission("viewWeightLog")) {
        return (    
            <Layout>
                <Container>
                    <UnauthorizedPage />
                </Container>
            </Layout>
        )
    }

    return (
        <>
            <Layout>
                <Container>
                    <Box className="d-flex justify-content-between mb-2">
                        <Title>Weight Logs</Title>
                        <Box className="titleBox">
                            <ExportButton 
                                exportToCSV={exportToCSV}
                                exportToPdf={exportToPdf}
                                // exportToPdfWithImage={exportToPdfWithImage}
                                dayRange={dayRange + 1}
                            />
                        </Box>
                    </Box>
                    {/* {successText && <Alert severity="success">{successText}<br/></Alert>} */}
                    {errorText && <Alert severity="error" sx={{marginBottom: 2}}>{errorText}</Alert>}

                    <ImageModal 
                        show={showCaptureModal}
                        title={"Capture Images"}
                        handleClose={handleCloseModal}
                        dataList={captureImages}
                        count={captureCount}
                    />

                    <ImageModal 
                        show={showOrganicModal}
                        title={"Organic Images"}
                        handleClose={handleCloseModal}
                        dataList={organicImages}
                    />

                    <ImageModal 
                        show={showNonOrganicModal}
                        title={"NonOrganic Images"}
                        handleClose={handleCloseModal}
                        dataList={nonOrganicImages}
                    />

                    <LoadingModal 
                        show={isPreparing}
                    />

                    <WeightLogFilterContainer
                        onChangeFilter={handleChangeFilterValue}
                        onChangeDateFilter={handleChangeDateFilterValue}
                        dateFilterValue={dateFilterValue}
                        filterValue={filterValue}
                        userInfo={userInfo}
                    />

                    <Box sx={{minWidth: "300px"}} className="d-flex justify-content-between">
                        <SummaryText>Total Weight - {totalWeightAmount || 0} kg</SummaryText>
                        <SummaryText>{weightLogTotalCount} WeightLog(s) found!</SummaryText>
                    </Box>

                    <TableContainer>
                    <InnterTableContainer>
                        <table className="styled-table">
                            <thead className="table-header">
                                <tr>
                                    <th>ID</th>
                                    <th>Digestor Time</th>
                                    { isSuperSpecialUser() &&
                                        <>
                                        <th>Organization</th>
                                        <th>Location</th>
                                        </>
                                    }
                                    <th>Machine</th>
                                    <th>Tenant</th>
                                    <th>Waste Weight</th>
                                    <th>Access Card</th>
                                    <th>Sync</th>
                                    <th>Show Images</th>
                                    <th>Remark</th>
                                </tr>
                            </thead>
                            <tbody className="table-data">
                                {
                                    renderTable()
                                }
                            </ tbody>
                        </table>
                    </InnterTableContainer>
                    </TableContainer>
                    <PaginationContainer>
                        <Pagination 
                            count={pageCount} 
                            onChange={handleChangePage} 
                            page={tablePage} 
                            variant="outlined" 
                            shape="rounded" size="large" 
                        />
                    </PaginationContainer>
                </Container>
            </Layout>
        </>
    );
};

export default WeightLog;