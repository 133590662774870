import React from 'react';
import { ChangeEvent, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import ChartContainer from './ChartContainer';
import { 
    ChartData, 
    ArcElement, 
    Tooltip, 
    Legend, 
    Chart, 
    LineElement, 
    PointElement, 
    LinearScale, 
    Title, 
    CategoryScale
} from 'chart.js';
import { Box, Typography } from "@mui/material";
import { Form } from "react-bootstrap";
import { getMonthChoices, getYearChoices } from "../../../utils/DateTimeUtil";
import CalendarModal from "../../Shared/CalendarModal/CalendarModal";
import { ParentContainer } from '../DashboardElements';
import { CommonFilter, getDefaultCommonFilters, HourFilter, DayFilter, MonthFilter, getDefaultDayFilters, DefaultHourFilters, DefaultMonthFilters } from "../../../types/NewDashboardType";
import { getCurrentLocalUser } from '../../../utils/UserUtil';
import { UserInfo } from '../../../types/UserAuth';
import { STATUS_CODES } from "../../../constants/GlobalConstants";
import { getStartDateAndEndDateOfAMonth } from '../../../utils/DateTimeUtil';
import { weightbyYearsOptions } from "../WeightChartOptions";
import { 
    getWeightByYearApi, 
    getWeightByStartDateEndDateApi,
    getWeightByDayApi } from "../../../services/RouteServices/NewDashboardApi";
import { 
    BarChartWeightRequestType,
    TotalWeightByYearRequestType,
    BarChartWeightByDayRequestType,
    BarChartWeightResponseType, 
 } from "../../../types/NewDashboardType";

Chart.register(ArcElement, Tooltip, Legend, LineElement, PointElement, LinearScale, CategoryScale, Title);

interface TotalWeightLineChartProps {
  orgId: number;
  locationId: number;
  machineId: number;
  tenantId: number;
}

const TotalWeightLineChart: React.FC<TotalWeightLineChartProps> = ({orgId, locationId, machineId, tenantId }) => {
  const userInfo: UserInfo = getCurrentLocalUser();

  const [commonFilters, setCommonFilters] = useState<CommonFilter>(getDefaultCommonFilters())
  const [monthFilters, setMonthFilters] = useState<MonthFilter>(DefaultMonthFilters)
  const [dayFilters, setDayFilters] = useState<DayFilter>(getDefaultDayFilters())
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear().toString())
  const [showStartDateModal, setShowStartDateModal] = useState(false)
  const [showEndDateModal, setShowEndDateModal] = useState(false)
  const [minMaxEndDate, setMinMaxEndDate] = useState({min: "", max: ""})
  const [showDayModal, setDayModal] = useState(false)
  const [hourFilters, setHourFilters] = useState<HourFilter>(DefaultHourFilters)
  const [barChartItemsPerPage, setBarChartItemsPerPage] = useState(10);
  const [weightChartData, setWeightChartData] = useState<ChartData<"line", number[], unknown>>({
    labels: [],
    datasets: [
      {
        label: 'Total Weight (kg)',
        data: [],
        backgroundColor: '#FFA500',
        borderColor: '#FFA500',
        pointBorderColor: '#FFA500',
        pointBackgroundColor: '#FFA500',
      },
    ],
  });

    useEffect(() => {
        const startDateOfDay = new Date(dayFilters.startDate)
            const endDateOfDay = new Date(dayFilters.endDate)
            const minEndDate = new Date(minMaxEndDate.min)
            const maxEndDate = new Date(minMaxEndDate.max)
            const daysDiff = (endDateOfDay.getTime() - startDateOfDay.getTime()) / (1000 * 60 * 60 * 24)
        if(commonFilters.view == '1'){
            const {startDate, endDate} = getStartDateAndEndDateOfAMonth(+monthFilters.month - 1, +monthFilters.year);
            getTotalWeightByStartDateEndDate(startDate, endDate);
        }else if(commonFilters.view == '2'){
            getTotalWeightByYear();
        }
        else if(commonFilters.view === "3" &&
            startDateOfDay <= endDateOfDay &&
            minEndDate <= endDateOfDay && maxEndDate >= endDateOfDay &&
            daysDiff <= +dayFilters.range){
            getTotalWeightByStartDateEndDate(dayFilters.startDate, dayFilters.endDate);
        }
        else if(commonFilters.view === "4"){
            getTotalWeightByDay(hourFilters.day);
        }
    }, [hourFilters, dayFilters, monthFilters, selectedYear, commonFilters.view, orgId, locationId, machineId, tenantId]);

    useEffect(() => {
        if (dayFilters.startDate) {
            const startDateObj = new Date(dayFilters.startDate)
            const endDateObj = new Date(dayFilters.endDate)
            const newStartDateObj = new Date(startDateObj.getTime());
            newStartDateObj.setDate(newStartDateObj.getDate() + +dayFilters.range);

            setMinMaxEndDate({
                min: dayFilters.startDate,
                max: newStartDateObj.toISOString().split('T')[0],
            })

            if (endDateObj > newStartDateObj || startDateObj > endDateObj) {
                setDayFilters(prev => ({...prev, endDate: newStartDateObj.toISOString().split('T')[0]}))
            }
        }
    }, [dayFilters.startDate])

    useEffect(() => {
        if (dayFilters.endDate) {
            const startDateObj = new Date(dayFilters.startDate)
            const endDateObj = new Date(dayFilters.endDate)
            const newEndDateObj = new Date(endDateObj.getTime())
            newEndDateObj.setDate(newEndDateObj.getDate() - +dayFilters.range)

            if (startDateObj < newEndDateObj || startDateObj > endDateObj) {
                setDayFilters(prev => ({...prev, startDate: newEndDateObj.toISOString().split('T')[0]}))
            }
        }
    }, [dayFilters.endDate])

  const loadDataToStates = (data: BarChartWeightResponseType) => {
    const updatedLabels = data.weightList.map(item => item.name);
    const updatedData = data.weightList.map(item => item.totalWeight);
    const totalPages = Math.ceil(updatedData.length / barChartItemsPerPage);

    setWeightChartData({
      labels: updatedLabels,
      datasets: [
        {
          label: 'Total Weight (kg)',
          data: updatedData,
        },
      ],
    });
  };

  const clearChart = () => {
    setWeightChartData({
      labels: [],
      datasets: [
        {
          label: 'Total Weight (kg)',
          data: [],
          backgroundColor: '#0000FF',
        },
      ],
    });
  };

  const getTotalWeightByYear = () => {
    const input: TotalWeightByYearRequestType = {
        orgId: orgId,
        locationId: locationId,
        machineId: machineId,
        tenantId: tenantId,
        year: selectedYear,
        isForAllOrganization: (orgId <= 0)
    }
    getWeightByYearApi(input)
    .then(res => {
        if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
            const data: BarChartWeightResponseType = res?.data?.data
            loadDataToStates(data)
        } else {
            clearChart();
        }
    }).catch(error => {
        console.log(error?.message)
    })
  }

  const getTotalWeightByStartDateEndDate = (startDate: string, endDate: string) => {
    const input: BarChartWeightRequestType = {
        orgId: orgId,
        locationId: locationId,
        machineId: machineId,
        tenantId: tenantId,
        startDate: startDate,
        endDate: endDate,
        isForAllOrganization: (orgId <= 0)
    }
    getWeightByStartDateEndDateApi(input)
    .then(res => {
        if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
            const data: BarChartWeightResponseType = res?.data?.data
            loadDataToStates(data)
        } else {
            clearChart();
        }
    }).catch(error => {
        console.log(error?.message)
    })
  }

  const getTotalWeightByDay = (day: string) => {
    const input: BarChartWeightByDayRequestType = {
        orgId: orgId,
        locationId: locationId,
        machineId: machineId,
        tenantId: tenantId,
        day: day,
        isForAllOrganization: (orgId <= 0)
    }
    getWeightByDayApi(input)
    .then(res => {
        if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
            const data: BarChartWeightResponseType = res?.data?.data
            loadDataToStates(data)
        } else {
            clearChart();
        }
    }).catch(error => {
        console.log(error?.message)
    })
  }
  
  const toggleStartDateModal = () => {
    setShowStartDateModal(prev => !prev)
  }

  const toggleEndDateModal = () => {
    setShowEndDateModal(prev => !prev)
  }

  const toggleDayModal = () => {
    setDayModal(prev => !prev)
  }

  const handleChangeHourFilter = (name: string, value: any) => {
    const day = value.getDate().toString().padStart(2, '0');
    const month = (value.getMonth() + 1).toString().padStart(2, '0');
    const year = value.getFullYear();
    value = `${year}-${month}-${day}`;
    setHourFilters(prev => ({...prev, [name]: value}))
  }

  const handleChangeDayFilter = (name: string, value: any) => {
    let copyDayFilters = {... dayFilters}

    if (name === "startDate" || name === "endDate") {
        const day = value.getDate().toString().padStart(2, '0');
        const month = (value.getMonth() + 1).toString().padStart(2, '0');
        const year = value.getFullYear();
        value = `${year}-${month}-${day}`;
    }
    copyDayFilters = {
        ...copyDayFilters,
        [name]: value
    }
    setDayFilters(copyDayFilters)
  }

  const handleChangeSelectedYear = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedYear(event.target.value)
  }

  const handleChangeMonthFilter = (name: string, value: any) => {
    setMonthFilters(prev => ({...prev, [name]: value}))
  }

  const handleChangeCommonFilter = (name: string, value: any) => {
    let copyCommonFilters = {...commonFilters}

    if (name === "org") {
        copyCommonFilters = {
            ...copyCommonFilters,
            [name]: value
        }
    } else {
        copyCommonFilters = {
            ...copyCommonFilters,
            [name]: value
        } 
    }
    setCommonFilters(copyCommonFilters)
  }

  return (
    <ParentContainer>
      <Box 
          sx={{
              display: "flex",
              gap: 2,
              flexWrap: "wrap",
              alignItems: "flex-end",
          }}
      >
          <Form.Select 
              name="view"
              onChange={(event) => handleChangeCommonFilter(event.target.name, event.target.value)}
              value={commonFilters.view}
              style={{width: "100px"}} 
          >
              <option value="1">Month</option>
              <option value="2">Year</option>
              <option value="3">Day</option>
              <option value="4">Hour</option>
          </Form.Select>
          {
              commonFilters.view === '1' &&
              <Box className="d-flex gap-2">
                  <Form.Select
                      name="year"
                      onChange={(event) => handleChangeMonthFilter(event.target.name, event.target.value)}
                      value={monthFilters.year}
                      style={{width: "150px"}} 
                  >
                      {
                          getYearChoices().map((year) => <option value={year.value} key={year.value}>{year.label}</option>)
                      }
                  </Form.Select>
                  <Form.Select
                      name="month"
                      onChange={(event) => handleChangeMonthFilter(event.target.name, event.target.value)}
                      value={monthFilters.month}
                      style={{width: "150px"}} 
                  >
                      {
                          getMonthChoices().map((month) => <option value={month.value} key={month.value}>{month.label}</option>)
                      }
                  </Form.Select>
              </Box>
          }
          {
              commonFilters.view === '2' &&
              <Form.Select
                  onChange={handleChangeSelectedYear}
                  value={selectedYear}
                  style={{width: "150px"}} 
              >
                  {
                      getYearChoices().map((year) => <option value={year.value} key={year.value}>{year.label}</option>)
                  }
              </Form.Select>
          }
          {
              commonFilters.view === '3' &&
              <Box
                  sx={{
                      display: "flex",
                      gap: 5,
                      width: "500px",
                      flexWrap: "wrap",
                      alignItems: "flex-end",
                  }}
              >
                  <Box>
                      <Typography>Start Date</Typography>
                      <button type="button" className="btn btn-outline-dark" onClick={toggleStartDateModal} >{dayFilters.startDate || "Start Date"}</button>
                  </Box>
                  <Box>
                      <Typography>End Date</Typography>
                      <button type="button" className="btn btn-outline-dark" onClick={toggleEndDateModal}>{dayFilters.endDate || "End Date"}</button>
                  </Box>
                  <CalendarModal 
                      show={showStartDateModal}
                      onCloseModal={toggleStartDateModal}
                      onChange={(value: Date) => {
                          handleChangeDayFilter("startDate", value)
                          toggleStartDateModal()
                      }}
                  />
                  <CalendarModal 
                      show={showEndDateModal}
                      onCloseModal={toggleEndDateModal}
                      onChange={(value: Date) => {
                        handleChangeDayFilter("endDate", value)
                          toggleEndDateModal()
                      }}
                      maxDate={minMaxEndDate.max ? new Date(minMaxEndDate.max) : new Date()}
                      minDate={minMaxEndDate.min ? new Date(minMaxEndDate.min) : new Date(2000, 1, 1)}
                  />
              </Box>
          }
          {
              commonFilters.view === '4' &&
              <Box>
                  <Box>
                      <button type="button" className="btn btn-outline-dark" onClick={toggleDayModal} >{`${hourFilters.day}` || "Select Day"}</button>
                  </Box>
                  <CalendarModal 
                      show={showDayModal}
                      onCloseModal={toggleDayModal}
                      onChange={(value: Date) => {
                          handleChangeHourFilter("day", value)
                          toggleDayModal()
                      }}
                  />
              </Box>
          }
      </Box>
    <ChartContainer>
      <Line data={weightChartData} options={weightbyYearsOptions} className='my-chart' />
    </ChartContainer>
    </ParentContainer>
  );
};

export default TotalWeightLineChart;
