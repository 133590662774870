export interface AlertUserType {
    id: number,
    organizationName: string,
    userId: number,
    userName: string,
    userType: string,
    remark: string,
    dailyReportSms: boolean,
    dailyReportEmail: boolean,
    machineHealthSms: boolean,
    machineHealthEmail: boolean,
    hardwareHealthSms: boolean,
    hardwareHealthEmail: boolean,
    createdBy?: string;
    updatedBy?: string;
    deletedBy?: string;
    createdAt?: string;
    updatedAt?: string;
    deletedAt?: string;
}

export interface AlertUserGETBodyType {
    offset: number,
    limit: number,
}

export interface AlertUserAddBodyType {
    userId: number,
    remark: string,
    dailyReportSms: boolean,
    dailyReportEmail: boolean,
    createdBy: string;
}

export interface AlertUserUpdateBodyType {
    id: number,
    remark: string,
    dailyReportSms: boolean,
    dailyReportEmail: boolean,
    updatedBy: string,
}

export interface AlertUserDeleteBodyType {
    id: number,
    deletedBy: string,
}

export const AlertUserDefault = {
    id: 0,
    organizationName: "",
    userId: -1,
    userName: "",
    userType: "",
    remark: "",
    dailyReportSms: false,
    dailyReportEmail: false,
    machineHealthSms: false,
    machineHealthEmail: false,
    hardwareHealthSms: false,
    hardwareHealthEmail: false,
}


