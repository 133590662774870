import { Alert, Box, Pagination } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import XLSX from "xlsx";
import { LOG_LEVEL, PAGE_LIMIT, STATUS_CODES } from "../../constants/GlobalConstants";
import { operatorLogGetListByOrgIdApi } from "../../services/RouteServices/OperatorLogApi";
import { OperatorLogData } from "../../types/OperatorLogType";
import { UserAuthType, UserInfo } from "../../types/UserAuth";
import { DateFilterValue } from "../../types/global/FilterValueType";
import { convertDateFormat, convertToLocaleDate, getCurrentLocaleDate } from "../../utils/DateTimeUtil";
import { getDefaultDateFilterValue, getDefaultFilterValue } from "../../utils/DefaultFilterValueUtil";
import { addEventLog } from "../../utils/EventLogUtil";
import { addNormalLog } from "../../utils/LoggerUtil";
import { getCurrentLocalUser, isRootAndSuperUsers, isSuperSpecialUser } from "../../utils/UserUtil";
import Layout from "../Layout/Layout";
import { Container, InnterTableContainer, PaginationContainer, TableContainer } from "../Shared/Common/Containers";
import { SummaryText, Title } from "../Shared/Common/Titles";
import UnauthorizedPage from "../Shared/ErrorPages/UnauthorizedPage";
import ExportButton from "../Shared/Export/Export";
import ReadMoreModal from "../Shared/ReadMoreModal/ReadMoreModal";
import OperatorLogFilterContainer from "./OperatorLogFilterContainer";
import { UserAuthContext } from "../../contexts/UserAuthContext";
import { generatePDF, generateCSV } from '../../utils/ExportUtil';

const OperatorLog = () => {
    const tableLimit = PAGE_LIMIT;
    const userInfo: UserInfo = getCurrentLocalUser();
    const { hasPermission } = useContext(UserAuthContext) as UserAuthType

    const [successText, setSuccessText] = useState<string>("")
    const [errorText, setErrorText] = useState<string>("")
    const [operatorLogTotalCount, setOperatorLogTotalCount] = useState<number>(0)
    const [operatorLogs, setOperatorLogs] = useState<OperatorLogData[]>([])
    const [tablePage, setTablePage] = useState(1);
    const [tableOffset, setTableOffset] = useState(0);
    const [filterValue, setFilterValue] = useState(getDefaultFilterValue())
    const [dateFilterValue, setDateFilterValue] = useState<DateFilterValue>(getDefaultDateFilterValue())
    const [showReadMoreModal, setShowReadMoreModal] = useState(false)
    const [operatorLogMessage, setOperatorLogMessage] = useState("");

    const pageCount = (Math.ceil(operatorLogTotalCount/tableLimit)) || 0

    useEffect(() => {
        setTablePage(1)
        setTableOffset(0)
    }, [filterValue, dateFilterValue])

    useEffect(() => {
        if (hasPermission("viewOperatorLog")) {
            getOperatorLogListByOrgId()
        }
    }, [tableOffset, filterValue, dateFilterValue])

    const handleChangeFilterValue = (operator: React.ChangeEvent<HTMLInputElement>) => {
        setFilterValue({
            ...filterValue,
            [operator.target.name]: operator.target.value
        })
    }

    const handleChangePage = (operator : React.ChangeEvent<unknown>, pageValue : number) => {
        setTablePage(pageValue);
        if (pageValue === 1) {
            setTableOffset(0);
        }
        else{
            const offsetValue = (tableLimit * (pageValue - 1));
            setTableOffset(offsetValue);
        }
    };

    const handleChangeDateFilterValue = (key: "startDate" | "endDate", value: Date) => {
        const day = value.getDate().toString().padStart(2, '0');
        const month = (value.getMonth() + 1).toString().padStart(2, '0');
        const year = value.getFullYear();
        const formattedDate = `${year}-${month}-${day}`;
        setDateFilterValue({...dateFilterValue, [key]: formattedDate})
    }

    const handleClickClose = () => {
        setShowReadMoreModal(false)
        setOperatorLogMessage("")
    }

    const handleClickReadmore = (operatorLogMessage: string) => {
        setShowReadMoreModal(true)
        setOperatorLogMessage(operatorLogMessage)
    }

    const exportToCSV = async () => { 
        let exportedData = await getExportedData()
        const headerToKeyMap: { [key: string]: string } = {
            "Id": "id",
            "Organization": "organizationName",
            "User": "username",
            "Operator Log": "logMessage",
            "Created At": "createdAt"
          };
        const dateTimeColumns = ["createdAt"];
        generateCSV(headerToKeyMap, exportedData, dateTimeColumns, "Overall", "operator-logs", userInfo.userName)
    };

    const exportToPdf = async () => {
        let exportedData = await getExportedData()
        const headers = [
            "Id", "Organization", "User",
            "Operator Log", "Created At"
        ];
        const dataKeys = [
            "id", "organizationName", "username",
            "logMessage", "createdAt"
        ];
        const columnWidths = [25, 35, 35, 130, 40];
        const dateTimeColumns = ["createdAt"];
        generatePDF(headers, dataKeys, columnWidths, exportedData, dateTimeColumns, 0, "", "Operator Log", "operator-logs", userInfo.userName, 9, true)
    }

    const getExportedData = async () => {
        let exportedData = []
        try {
            exportedData = await getOperatorLogExportListByOrgId()
        } catch(e) {
            console.log(e)
        }
        return exportedData
    }

    const getOperatorLogListByOrgId = () => {
        const input = {
            orgId: isSuperSpecialUser() ? 0 : userInfo.orgId,
            startDate: convertDateFormat(dateFilterValue?.startDate || ""),
            endDate: convertDateFormat(dateFilterValue?.endDate || ""),
            searchText: filterValue.search,
            limit: tableLimit,
            offset: tableOffset
        }
        operatorLogGetListByOrgIdApi(input)
        .then(res => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                setOperatorLogs(res?.data?.data?.operatorLogs)
                setOperatorLogTotalCount(res?.data?.data?.totalCount)
            } else {
                setErrorText(res?.data?.message)
            }
        }).catch(error => {
            setSuccessText("");
            setErrorText(error?.message)
        })
    }

    const getOperatorLogExportListByOrgId = async (): Promise<any> => {
        const input = {
            orgId: isSuperSpecialUser() ? 0 : userInfo.orgId,
            startDate: convertDateFormat(dateFilterValue?.startDate || ""),
            endDate: convertDateFormat(dateFilterValue?.endDate || ""),
            searchText: filterValue.search,
            limit: 0,
            offset: 0
        }
        return operatorLogGetListByOrgIdApi(input)
        .then(res => {
            return new Promise((resolve, reject) => {
                if (res?.data?.status === STATUS_CODES.SUCCESS_CODE)
                    resolve(res?.data?.data?.operatorLogs)
                else 
                    reject([])
            })
        }).catch(error => {
            return Promise.reject([])
        })
    }

    const renderTable = () => {
        if (operatorLogs && operatorLogs.length === 0) {
            return (
                <tr>
                    <td>No data found</td>
                </tr>
            )
        }
        return (
            operatorLogs.map(operatorLog => {
                let colorOfLogLevel = "";
                let logLevel = operatorLog.logLevel;
                if (logLevel === LOG_LEVEL.WARN ) {
                    colorOfLogLevel = "#eb790e";
                } else if (logLevel === LOG_LEVEL.ALERT ) {
                    colorOfLogLevel = "#eb0e15";
                } else if (logLevel === LOG_LEVEL.SUCCESS) {
                    colorOfLogLevel = "#539165"
                }   
                return (
                <tr key={operatorLog.id} style={{color: colorOfLogLevel}}>
                    <td>{operatorLog.id}</td>
                    <td>{convertToLocaleDate(operatorLog.createdAt!)}</td>
                    <td>{operatorLog.organizationName || "-"}</td>
                    <td>{operatorLog.username || "-"}</td>
                    <td>
                        {
                            operatorLog.logMessage ?
                            <div className='d-flex align-items-center'>
                                <p style={{overflow: "hidden", width: "500px"}} className='m-0'>
                                    {operatorLog.logMessage.slice(0, 80)}
                                </p>
                                {
                                    operatorLog?.logMessage?.length > 80 && 
                                    <button onClick={() => handleClickReadmore(operatorLog.logMessage!)} className='btn btn-outline-light text-primary'>more...</button>
                                }
                            </div>:
                            "-"
                        }
                    </td>
                </tr>)
            })
        )
    }

    if (!hasPermission("viewOperatorLog")) {
        return (    
            <Layout>
                <Container>
                    <UnauthorizedPage />
                </Container>
            </Layout>
        )
    }

    return (  
        <Layout>
                <Container>
                    <Box className="d-flex justify-content-between mb-2">
                        <Title>Operator Logs</Title>
                        <Box className="titleBox">
                            <ExportButton 
                                exportToCSV={exportToCSV}
                                exportToPdf={exportToPdf}
                            />
                        </Box>
                    </Box>

                    <OperatorLogFilterContainer 
                        filterValue={filterValue}
                        onChangeFilter={handleChangeFilterValue}
                        dateFilterValue={dateFilterValue}
                        onChangeDateFilter={handleChangeDateFilterValue}
                    />

                    {successText && <Alert severity="success" sx={{marginBottom: 2}}>{successText}<br/></Alert>}
                    {errorText && <Alert severity="error" sx={{marginBottom: 2}}>{errorText}</Alert>}

                    <ReadMoreModal 
                        title={"Operator Log"}
                        show={showReadMoreModal}
                        handleClickClose={handleClickClose}
                        bodyText={operatorLogMessage}
                    />

                    <SummaryText>{operatorLogTotalCount} Operator Logs found!</SummaryText>
                    <TableContainer>
                    <InnterTableContainer>
                        <table className="styled-table">
                            <thead className="table-header">
                                <tr>
                                    <th>ID</th>
                                    <th>Datetime</th>
                                    <th>Organization</th>
                                    <th>User</th>
                                    <th>Operator Log</th>
                                </tr>
                            </thead>
                            <tbody className="table-data">
                                {renderTable()}
                            </ tbody>
                        </table>
                    </InnterTableContainer>
                    </TableContainer>
                    <PaginationContainer>
                        <Pagination count={pageCount} onChange={handleChangePage} page={tablePage} variant="outlined" shape="rounded" size="large" />
                    </PaginationContainer>
                </Container>
        </Layout>
    );
}
 
export default OperatorLog;