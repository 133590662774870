import { Alert, Box, Pagination } from "@mui/material";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import * as React from 'react';
import { useEffect, useState } from 'react';
import XLSX from "xlsx";
import logoImage from '../../assets/logo/env-logo.png';
import { LOG_LEVEL, PAGE_LIMIT, STATUS_CODES } from '../../constants/GlobalConstants';
import { eventLogGetExportListByOrgIdApi, eventLogGetListByOrgIdApi } from '../../services/RouteServices/EventLogApi';
import { EventLogData } from '../../types/EventLogType';
import { UserInfo } from '../../types/UserAuth';
import { DateFilterValue } from '../../types/global/FilterValueType';
import { convertDateFormat, convertToLocaleDate, getCurrentLocaleDate } from '../../utils/DateTimeUtil';
import { getDefaultDateFilterValue, getDefaultFilterValue } from '../../utils/DefaultFilterValueUtil';
import { addEventLog } from '../../utils/EventLogUtil';
import { addNormalLog } from '../../utils/LoggerUtil';
import { getCurrentLocalUser, isRootAndSuperUsers, isSuperSpecialUser } from '../../utils/UserUtil';
import Layout from "../Layout/Layout";
import { Container, InnterTableContainer, PaginationContainer, TableContainer } from "../Shared/Common/Containers";
import { SummaryText, Title } from "../Shared/Common/Titles";
import UnauthorizedPage from '../Shared/ErrorPages/UnauthorizedPage';
import ExportButton from '../Shared/Export/Export';
import ReadMoreModal from '../Shared/ReadMoreModal/ReadMoreModal';
import EventLogFilterContainer from './EventLogFilterContainer';
import { generatePDF, generateCSV } from '../../utils/ExportUtil';

const loadEventLogExportList = (dataList: EventLogData[]) => {
    if (!dataList) return []
    return dataList.map(data => {
        return {
            Id: data.id,
            "Organization": data.organizationName,
            "User": data.userName,
            "Event Log": data.eventLog,
            "Created At": convertToLocaleDate(data.createdAt!),
        }
    })
}

const EventLog = () => {
    const tableLimit = PAGE_LIMIT;
    const userInfo: UserInfo = getCurrentLocalUser();

    const [successText, setSuccessText] = useState<string>("")
    const [errorText, setErrorText] = useState<string>("")
    const [eventLogTotalCount, setEventLogTotalCount] = useState<number>(0)
    const [eventLogs, setEventLogs] = useState<EventLogData[]>([])
    const [tablePage, setTablePage] = useState(1);
    const [tableOffset, setTableOffset] = useState(0);
    const [filterValue, setFilterValue] = useState(getDefaultFilterValue())
    const [dateFilterValue, setDateFilterValue] = useState<DateFilterValue>(getDefaultDateFilterValue())
    const [showReadMoreModal, setShowReadMoreModal] = useState(false)
    const [eventLogMessage, setEventLogMessage] = useState("");

    const pageCount = (Math.ceil(eventLogTotalCount/tableLimit)) || 0

    useEffect(() => {
        setTablePage(1)
        setTableOffset(0)
    }, [filterValue, dateFilterValue])

    useEffect(() => {
        if (isRootAndSuperUsers()) {
            getEventLogListByOrgId()
        }
    }, [tableOffset, filterValue, dateFilterValue])

    const handleChangeFilterValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterValue({
            ...filterValue,
            [event.target.name]: event.target.value
        })
    }

    const handleChangePage = (event : React.ChangeEvent<unknown>, pageValue : number) => {
        setTablePage(pageValue);
        if (pageValue === 1) {
            setTableOffset(0);
        }
        else{
            const offsetValue = (tableLimit * (pageValue - 1));
            setTableOffset(offsetValue);
        }
    };

    const handleChangeDateFilterValue = (key: "startDate" | "endDate", value: Date) => {
        const day = value.getDate().toString().padStart(2, '0');
        const month = (value.getMonth() + 1).toString().padStart(2, '0');
        const year = value.getFullYear();
        const formattedDate = `${year}-${month}-${day}`;
        setDateFilterValue({...dateFilterValue, [key]: formattedDate})
    }

    const handleClickClose = () => {
        setShowReadMoreModal(false)
        setEventLogMessage("")
    }

    const handleClickReadmore = (eventLogMessage: string) => {
        setShowReadMoreModal(true)
        setEventLogMessage(eventLogMessage)
    }

    const generatePDF1 = (dataList: any) => {
        const doc = new jsPDF('landscape', 'mm', 'a4');

        const headers = [
            "Id", "Organization", "User",
            "Event Log", "Created At"
        ];
        const dataKeys = [
            "id", "organizationName", "userName",
            "eventLog", "createdAt"
        ];

        const columnWidths = [25, 35, 35, 130, 40];
        // Generate the columnStyles object dynamically
        const columnStyles: any = {};
        for (let i = 0; i < headers.length; i++) {
          columnStyles[dataKeys[i]] = { cellWidth: columnWidths[i] };
        }

        const getTextColor = (data: any): [number, number, number] => {
            const logLevel = data.row.raw.eventLevel;
            if (logLevel === LOG_LEVEL.WARN ) {
                return [235, 121, 14];
            } else if (logLevel === LOG_LEVEL.ALERT ) {
                return [235, 14, 21];
            } else if (logLevel === LOG_LEVEL.SUCCESS) {
                return [83, 145, 101]
            }  else {
                return [0, 0, 0]
            }
        };

        autoTable(doc, {
            head: [headers],
            body: dataList,
            columns: dataKeys.map((key) => ({ dataKey: key })),
            columnStyles: columnStyles,
            margin: { top: 20, left: 15},
            didParseCell: function (data) {
                if (data.section === "head") {
                  data.cell.styles.fontSize = 9;
                  data.cell.styles.textColor = [255, 255, 255];
                  data.cell.styles.fillColor = [0, 0, 0];
                } else if (data.section === "body") {
                    const textColor = getTextColor(data)
                    data.cell.styles.textColor = textColor;
                }
              },
            didDrawPage: function (data) {
                doc.setFontSize(18);
                doc.text("Event Log", data.settings.margin.left, 10);
                // Add the logo image
                const logoWidth = 20;
                const logoHeight = 10;
                const logoX = data.settings.margin.left + 250;
                const logoY = 5;
                doc.addImage(logoImage, 'PNG', logoX, logoY, logoWidth, logoHeight);
            }
        })
        doc.save(`eventlogs-${getCurrentLocaleDate()}.pdf`)
    }

    const exportToCSV = async () => { 
        let exportedData = await getExportedData()
        const headerToKeyMap: { [key: string]: string } = {
            "Id": "id",
            "Organization": "organizationName",
            "User": "locationName",
            "Event Log": "eventLog",
            "Created At": "createdAt"
          };
        const dateTimeColumns = ["createdAt"];
        generateCSV(headerToKeyMap, exportedData, dateTimeColumns, "Overall", "eventlogs", userInfo.userName)
    };

    const exportToPdf1 = async () => {
        let exportedData = await getExportedData()
        const dataList = exportedData ? exportedData.map((data: EventLogData) => ({...data, createdAt: convertToLocaleDate(data.createdAt!)})) : []
        generatePDF1(dataList)
        const log: string = `${userInfo.userName} has downloaded eventlogs-${getCurrentLocaleDate()}.pdf`
        addEventLog(
            log,
            "",
            LOG_LEVEL.INFO
        )
        addNormalLog(log, LOG_LEVEL.INFO)
    }

    const exportToPdf = async () => {
        let exportedData = await getExportedData()
        const headers = [
            "Id", "Organization", "User",
            "Event Log", "Created At"
        ];
        const dataKeys = [
            "id", "organizationName", "userName",
            "eventLog", "createdAt"
        ];
        const columnWidths = [25, 35, 35, 130, 40];
        const dateTimeColumns = ["createdAt"];
        generatePDF(headers, dataKeys, columnWidths, exportedData, dateTimeColumns, 0, "", "Event Log", "eventlogs", userInfo.userName, 9, true)
    }

    const getExportedData = async () => {
        let exportedData = []
        try {
            exportedData = await getEventLogExportListByOrgId()
        } catch(e) {
            exportedData = []
        }
        return exportedData
    }

    const getEventLogListByOrgId = () => {
        const input = {
            orgId: isSuperSpecialUser() ? 0 : userInfo.orgId,
            startDate: convertDateFormat(dateFilterValue?.startDate || ""),
            endDate: convertDateFormat(dateFilterValue?.endDate || ""),
            searchText: filterValue.search,
            limit: tableLimit,
            offset: tableOffset
        }
        eventLogGetListByOrgIdApi(input)
        .then(res => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                setEventLogs(res?.data?.data?.eventLogList)
                setEventLogTotalCount(res?.data?.data?.totalCount)
            } else {
                setErrorText(res?.data?.message)
            }
        }).catch(error => {
            setSuccessText("");
            setErrorText(error?.message)
        })
    }

    const getEventLogExportListByOrgId = async (): Promise<any> => {
        const input = {
            orgId: isSuperSpecialUser() ? 0 : userInfo.orgId,
            startDate: convertDateFormat(dateFilterValue?.startDate || ""),
            endDate: convertDateFormat(dateFilterValue?.endDate || ""),
            searchText: filterValue.search,
        }
        return eventLogGetExportListByOrgIdApi(input)
        .then(res => {
            return new Promise((resolve, reject) => {
                if (res?.data?.status === STATUS_CODES.SUCCESS_CODE)
                    resolve(res?.data?.data?.eventLogList)
                else 
                    reject([])
            })
        }).catch(error => {
            return Promise.reject([])
        })
    }

    const renderTable = () => {
        if (eventLogs && eventLogs.length === 0) {
            return (
                <tr>
                    <td>No data found</td>
                </tr>
            )
        }
        return (
            eventLogs.map(eventLog => {
                let colorOfLogLevel = "";
                let logLevel = eventLog.eventLevel;
                if (logLevel === LOG_LEVEL.WARN ) {
                    colorOfLogLevel = "#eb790e";
                } else if (logLevel === LOG_LEVEL.ALERT ) {
                    colorOfLogLevel = "#eb0e15";
                } else if (logLevel === LOG_LEVEL.SUCCESS) {
                    colorOfLogLevel = "#539165"
                }   
                return (
                <tr key={eventLog.id} style={{color: colorOfLogLevel}}>
                    <td>{eventLog.id}</td>
                    <td>{convertToLocaleDate(eventLog.createdAt!)}</td>
                    <td>{eventLog.organizationName || "-"}</td>
                    <td>{eventLog.userName || "-"}</td>
                    <td>
                        {
                            eventLog.eventLog ?
                            <div className='d-flex align-items-center'>
                                <p style={{overflow: "hidden", width: "500px"}} className='m-0'>
                                    {eventLog.eventLog.slice(0, 80)}
                                </p>
                                {
                                    eventLog?.eventLog?.length > 80 && 
                                    <button onClick={() => handleClickReadmore(eventLog.eventLog!)} className='btn btn-outline-light text-primary'>more...</button>
                                }
                            </div>:
                            "-"
                        }
                    </td>
                </tr>)
            })
        )
    }
    
    if (!isRootAndSuperUsers()) {
        return (    
            <Layout>
                <Container>
                    <UnauthorizedPage />
                </Container>
            </Layout>
        )
    }

    return (  
        <>
            <Layout>
                <Container>
                    <Box className="d-flex justify-content-between mb-2">
                        <Title>Event Logs</Title>
                        <Box className="titleBox">
                            <ExportButton 
                                exportToCSV={exportToCSV}
                                exportToPdf={exportToPdf}
                            />
                        </Box>
                    </Box>
                    <EventLogFilterContainer 
                        filterValue={filterValue}
                        onChangeFilter={handleChangeFilterValue}
                        dateFilterValue={dateFilterValue}
                        onChangeDateFilter={handleChangeDateFilterValue}
                    />
                    
                    {/* {successText && <ToastContainer autoClose={3000} />} */}
                    {successText && <Alert severity="success" sx={{marginBottom: 2}}>{successText}<br/></Alert>}
                    {errorText && <Alert severity="error" sx={{marginBottom: 2}}>{errorText}</Alert>}


                    <ReadMoreModal 
                        title={"Event Log"}
                        show={showReadMoreModal}
                        handleClickClose={handleClickClose}
                        bodyText={eventLogMessage}
                    />
                    
                    <SummaryText>{eventLogTotalCount} Event Logs found!</SummaryText>
                    <TableContainer>
                    <InnterTableContainer>
                        <table className="styled-table">
                            <thead className="table-header">
                                <tr>
                                    <th>ID</th>
                                    <th>Datetime</th>
                                    <th>Organization</th>
                                    <th>User</th>
                                    <th>Event Log</th>
                                </tr>
                            </thead>
                            <tbody className="table-data">
                                {renderTable()}
                            </ tbody>
                        </table>
                    </InnterTableContainer>
                    </TableContainer>
                    <PaginationContainer>
                        <Pagination count={pageCount} onChange={handleChangePage} page={tablePage} variant="outlined" shape="rounded" size="large" />
                    </PaginationContainer>
                </Container>
            </Layout>
        </>
    );
}
 
export default EventLog;