import { Box } from "@mui/material";

const PieChartContainer = ({children}: any) => {
    return (  
      <Box
        sx={(theme) => ({
            display: "flex",
            justifyContent: "center", 
            alignItems: "center",
            flexWrap: "wrap", 
            maxHeight: 480,
            minHeight: 480,
            width: "100%",
            backgroundColor: "#fff",
            paddingX: 2,
            [theme.breakpoints.down("md")]: {
                maxHeight: "400px", 
              },
              [theme.breakpoints.down("sm")]: {
                maxHeight: "300px",
                paddingX: 1,
              },
        })}
      >
        {children}
      </Box>   
    );
}
 
export default PieChartContainer;