import { Box } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import XLSX from "xlsx";
import { LOG_LEVEL, STATUS_CODES } from "../../../constants/GlobalConstants";
import useFilterLocations from "../../../custom-hooks/useFilterLocations";
import { useFilterMachinesByLocationIds } from "../../../custom-hooks/useFilterMachinesByLocationIds";
import { useFilterOrganizations } from "../../../custom-hooks/useFilterOrganizations";
import { useFilterTenants } from "../../../custom-hooks/useFilterTenants";
import { getDailyOverallDataApi, getMonthlyWeightAmountForEachTenantApi } from "../../../services/RouteServices/ReportDetailsApi";
import { ChartData } from "../../../types/ReportType";
import {
    getCurrentLocaleDate,
    getMonthName,
    getStartDateAndEndDateOfAMonth
} from "../../../utils/DateTimeUtil";
import { addEventLog } from "../../../utils/EventLogUtil";
import { addNormalLog } from "../../../utils/LoggerUtil";
import { getCurrentLocalUser } from "../../../utils/UserUtil";
import Layout from "../../Layout/Layout";
import { Container } from "../../Shared/Common/Containers";
import { Title } from "../../Shared/Common/Titles";
import GoBackButton from "../../Shared/GoBackButton/GoBackButton";
import LoadingModal from "../../Shared/LoadingModal/LoadingModal";
import { weightAmountOptions } from "../ChartConstant";
import DailyTable from "./DailyTable";
import MonthlyTable from "./MonthlyTable";
import ReportDetailsButtons from "./ReportDetailsButtons";
import ReportDetailsFilters from "./ReportDetailsFilters";
import {
    getSelectedMachineName,
    getSelectedOrganizationName,
    loadDailyChartData,
    loadDailyReports,
    loadDailyReportsForSpecialPdf,
    loadDoorOpenChartDataForPdf,
    loadDoorScanChartDataForPdf,
    loadPdfViewTenantWeightAmountList,
    loadWeightAmountChartDataForPdf,
    sortData
} from "./general_functions";
// import { generatePDF, generateSpecialPDF } from "./generate-pdf";
import { generatePDF, generateCSV, generateSpecialPDF } from '../../../utils/ExportUtil';

export interface FilterValue {
    organizationId: number,
    locationId: number,
    machineId: number,
    year: number,
    month: number, 
    order: string,
}

const ReportDetails = ({

}: any) => {
    const userInfo =  getCurrentLocalUser()

    const DefaultFilterValues = {
        organizationId: userInfo.orgId,
        machineId: 0,
        locationId: 0,
        year: 0,
        month: 0,
        order: "desc"
    }

    const [filterValues, setFilterValues] = useState<FilterValue>(DefaultFilterValues as FilterValue)
    const [isPreparing, setIsPreparing] = useState<boolean>(false);
    const [currentView, setCurrentView] = useState("daily")
    const [previewDailyTableData, setPreviewDailyTableData] = useState<{ [key: string]: any }[]>([]);
    const [previewDailyTableHeaders, setPreviewDailyTableHeaders] = useState<string[]>([])
    const [previewMonthlyTableData, setPreviewMonthlyTableData] = useState<{ [key: string]: any }[]>([]);
    const [previewChartData, setPreviewChartData] = useState<ChartData | null>(null);

    const {organizations} = useFilterOrganizations()
    const {locations} = useFilterLocations(filterValues.organizationId, [filterValues.organizationId])
    const {machines} = useFilterMachinesByLocationIds([filterValues.locationId], [filterValues.locationId])
    const {tenants} = useFilterTenants(filterValues.machineId, [filterValues.machineId])
    const tenantIdList = tenants?.map(tenant => tenant.id!)

    useEffect(() => {
        setFilterValues({...filterValues, machineId: 0, locationId: 0})
    }, [filterValues.organizationId])

    useEffect(() => {
        setFilterValues({...filterValues, machineId: 0})
    }, [filterValues.locationId])

    useEffect(() => {
        if (currentView === "daily") {
            setPreviewDailyTableData(prev => {
                const sortedData = sortData(prev.slice(1,), filterValues.order)
                return [prev[0], ...sortedData]
            })
        }
    }, [filterValues.order])

    const exportToExcel = async (weightAmounts: { [key: string]: any }[]) => {  
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(weightAmounts);
        XLSX.utils.book_append_sheet(workbook, worksheet, "Details");
        XLSX.writeFile(workbook, `reports-details-${getCurrentLocaleDate()}.xlsx`);
        const log: string = `${userInfo.userName} has downloaded reports-details-${getCurrentLocaleDate()}.xlsx`
        addEventLog(
            log,
            "",
            LOG_LEVEL.INFO
        )
        addNormalLog(log, LOG_LEVEL.INFO)
    };

    const exportToPdf = async (weightAmounts: { [key: string]: any }[]) => {
        const filterNames = {
            organization: getSelectedOrganizationName(filterValues.organizationId, organizations),
            machine: getSelectedMachineName(filterValues.machineId, machines),
            year: filterValues.year,
            month: getMonthName(filterValues.month)
        }
        const headers = [
            "Tenants", "Monthly Data"
        ];
        const dataKeys = [
            "tenant", "weightAmount"
        ];
        const text = `Organization: ${filterNames.organization} | Machine: ${filterNames.machine} | Year: ${filterNames.year} | Month: ${filterNames.month}`;
        generatePDF(headers, dataKeys, [], weightAmounts, [], 0, text, "Monthly Tenant Weight", "reports-details", userInfo.userName, 12, false)
    }

    const exportToSpecialPdf1 = async (
        dailyData: any[], monthlyData: any[], 
        weightChartData: any[], doorOpenChartData: any[], doorScanChartData: any[],
        machine: string, organization: string
    ) => {
        // generateSpecialPDF(
        //     dailyData, monthlyData, weightChartData, 
        //     doorOpenChartData, doorScanChartData,
        //     filterValues, machine, organization
        // )
    }

    const exportToSpecialPdf = async (
        dailyData: any[], monthlyData: any[], 
        weightChartData: any[], doorOpenChartData: any[], doorScanChartData: any[],
        machine: string, organization: string
    ) => {
        generateSpecialPDF(
            dailyData, monthlyData, weightChartData, 
            doorOpenChartData, doorScanChartData,
            filterValues.year, filterValues.month, machine, organization, "reports-details", userInfo.userName
        )
    }

    const handleChangeFilterValue = (event: ChangeEvent<HTMLSelectElement>) => {
        setFilterValues({
            ...filterValues,
            [event.target.name]: event.target.value
        })
    }

    const handleClickDaily = async () => {
        console.log("Hello World")
        setIsPreparing(true)
        const results = await getDailyOverallData()
        const {result} = loadDailyReports(results, filterValues.order)
        setIsPreparing(false)
        exportToExcel(result || [])
    }

    const handleClickMonthly = async () => {
        setIsPreparing(true)
        const results = await getMonthlyWeightAmountForEachTenant()
        setIsPreparing(false)
        exportToPdf(results)
    }

    const handleClickSpecialPdf = async () => {
        setIsPreparing(true)
        const monthlyData = await getMonthlyWeightAmountForEachTenant()
        const result = await getDailyOverallData()
        const dailyData = loadDailyReportsForSpecialPdf(result)
        setIsPreparing(false)
        const weightChartData = loadWeightAmountChartDataForPdf(result?.dailyData || [])
        const doorOpenChartData = loadDoorOpenChartDataForPdf(result?.dailyData || [])
        const doorScanChartData = loadDoorScanChartDataForPdf(result?.dailyData || [])
        exportToSpecialPdf(
            dailyData, monthlyData, weightChartData, 
            doorOpenChartData, doorScanChartData, result?.machine, result?.organization)
    }

    const handlePreviewDaily = async () => {
        setCurrentView("daily")
        setIsPreparing(true)
        setPreviewMonthlyTableData([])
        setPreviewChartData(null)
        const results = await getDailyOverallData()
        const {keys, result} = loadDailyReports(results, filterValues.order)
        setPreviewDailyTableData(result || [])
        setPreviewDailyTableHeaders(keys || [])
        setIsPreparing(false)
    }

    const handlePreviewMonthly = async () => {
        setCurrentView("monthly")
        setIsPreparing(true)
        setPreviewDailyTableData([])
        setPreviewChartData(null)
        const results = await getMonthlyWeightAmountForEachTenant()
        setPreviewMonthlyTableData(results)
        setIsPreparing(false)
    }

    const handlePreviewChart = async () => {
        setCurrentView("chart")
        setIsPreparing(true)
        setPreviewMonthlyTableData([])
        setPreviewDailyTableData([])
        const result = await getDailyOverallData()
        const chartData = loadDailyChartData(result?.dailyData || [])
        setPreviewChartData(chartData)
        setIsPreparing(false)
    }

    const getDailyOverallData = async () => {
        const {startDate, endDate} = getStartDateAndEndDateOfAMonth(+filterValues.month - 1, +filterValues.year)
        const requestBody = {
            organizationId: filterValues.organizationId,
            machineId: filterValues.machineId,
            tenantIdList: tenantIdList,
            monthStartDate: startDate,
            monthEndDate: endDate,
        }
        return getDailyOverallDataApi(requestBody)
        .then(res => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                return Promise.resolve(res?.data?.data)
            } else {
                return Promise.resolve([])
            }
        }).catch((error) => {
            console.log(error)
            setIsPreparing(false)
            return Promise.resolve([])
        })
    }


    const getMonthlyWeightAmountForEachTenant = async (): Promise<any> => {
        const {startDate, endDate} = getStartDateAndEndDateOfAMonth(filterValues.month - 1, +filterValues.year)
        const requestBody = {
            organizationId: filterValues.organizationId,
            machineId: filterValues.machineId,
            tenantIdList: tenantIdList,
            startDate: startDate,
            endDate: endDate,
        }

        return getMonthlyWeightAmountForEachTenantApi(requestBody)
        .then(res => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                const results = loadPdfViewTenantWeightAmountList(res?.data?.data, filterValues.order)
                return Promise.resolve(results)
            } else {
                return Promise.resolve([])
            }
        }).catch(error => {
            console.log(error)
            setIsPreparing(false)
            return Promise.resolve([])
        })
    }

    return (  
        <Layout>
              <Container>
                <GoBackButton />
                <Title>Report Details</Title>
                <LoadingModal 
                    show={isPreparing}
                />
                <Box sx={{
                    display: "flex",
                    padding: 2,
                    flexWrap: "wrap",
                    alignItems: "center",
                    justifyContent: "space-between"
                }}>
                    <Box sx={(theme) => ({
                        width: "450px",
                        marginTop: 3,
                        [theme.breakpoints.down('md')]: {
                            width: "70%",
                        },
                        [theme.breakpoints.down('sm')]: {
                            width: "100%",
                        },
                    })}>
                        <ReportDetailsFilters 
                            filterValues={filterValues}
                            currentView={currentView}
                            machines={machines}
                            locations={locations}
                            organizations={organizations}
                            tenants={tenants}
                            handleChangeFilterValue={handleChangeFilterValue}
                        />
                        <ReportDetailsButtons 
                            filterValues={filterValues}
                            handlePreviewMonthly={handlePreviewMonthly}
                            handlePreviewDaily={handlePreviewDaily}
                            handlePreviewChart={handlePreviewChart}
                            handleClickMonthly={handleClickMonthly}
                            handleClickDaily={handleClickDaily}
                            handleClickSpecialPdf={handleClickSpecialPdf}
                        />
                    </Box>
                    {
                        (previewDailyTableData?.length > 0 && previewDailyTableData[0]) &&
                        <DailyTable 
                            headers={previewDailyTableHeaders}
                            data={previewDailyTableData}
                        />
                    }
                    {
                        (previewMonthlyTableData?.length > 0) &&
                        <MonthlyTable 
                            data={previewMonthlyTableData} 
                        />
                    }
                    {
                        (previewChartData) &&
                        <Box sx={(theme) => ({
                            width: "55%",
                            maxHeight: "400px",
                            boxShadow: "1px 1px 5px grey",
                            borderRadius: 7,
                            backgroundColor: "#f4f4f3",
                            padding: 3,
                            [theme.breakpoints.down('md')]: {
                                width: "100%",
                            },
                        })}>
                            <Bar options={weightAmountOptions} data={previewChartData!} className='my-chart' />
                        </Box>
                    }
                </Box>
              </Container>
        </Layout>
    );
}
 
export default ReportDetails;