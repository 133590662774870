import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Alert, Box } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { AxiosResponse } from "axios";
import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import ModalImage from 'react-modal-image';
import { LOG_LEVEL, NO_IMAGE_URL, PAGE_WITH_IMG_LIMIT, STATUS_CODES } from "../../constants/GlobalConstants";
import { CAPTURE_ADJUST_TIME_WITH_DETECTION_IN_SECOND, CAPTURE_END_RANGE_FOR_DETECTION_PAGE_IN_SECOND } from "../../constants/LfcContants";
import { UserAuthContext } from "../../contexts/UserAuthContext";
import useCaptureImages from "../../custom-hooks/useCaptureImages";
import { detectionGetExportListByOrgIdApi, detectionGetExportListByUserIdApi, detectionGetListByOrgIdApi, detectionGetListByUserIdApi } from "../../services/RouteServices/DetectionApi";
import '../../styling/customStyle.css';
import { DetectionData, DetectionDefault, DetectionGETRequestBody, DetectionListResponseBody, FirstCaptureImage } from "../../types/DetectionType";
import { UserAuthType, UserInfo } from "../../types/UserAuth";
import { DateFilterValue, FilterValue } from "../../types/global/FilterValueType";
import { addSecondToDate, convertDateFormat, convertDateFormatISO8061, convertToLocaleDate, getCurrentLocaleDate, getDayDifference } from "../../utils/DateTimeUtil";
import { getDefaultDateFilterValue, getDefaultFilterValue } from "../../utils/DefaultFilterValueUtil";
import { addEventLog } from "../../utils/EventLogUtil";
import { mergeImageUrlWithBaseUrl } from "../../utils/ImageUtil";
import { addNormalLog } from "../../utils/LoggerUtil";
import { getCurrentLocalUser, isSuperSpecialUser, isTenantUser } from "../../utils/UserUtil";
import Layout from "../Layout/Layout";
import { Container, InnterTableContainer, PaginationContainer, TableContainer } from "../Shared/Common/Containers";
import { SummaryText } from "../Shared/Common/Titles";
import UnauthorizedPage from "../Shared/ErrorPages/UnauthorizedPage";
import ImageModalButton from "../Shared/ImageModal/ImageModalButton";
import LoadingModal from "../Shared/LoadingModal/LoadingModal";
import ReadMoreModal from "../Shared/ReadMoreModal/ReadMoreModal";
import './Detection.css';
import DetectionFilterContainer from "./DetectionFilterContainer";
import { generatePdfWithImage } from "./generate-pdf";
import { getFirstCaptureImageOfDetectionApi } from '../../services/RouteServices/CaptureApi';

const Detection: React.FC = () => {
    const tableLimit = PAGE_WITH_IMG_LIMIT;
    const userInfo: UserInfo = getCurrentLocalUser();
    const { hasPermission } = useContext(UserAuthContext) as UserAuthType

    const [detectionList, setDetectionList] = useState<DetectionData[]>([]);
    const [firstCaptureImages, setFirstCaptureImages] = useState<FirstCaptureImage>({} as FirstCaptureImage)
    const [selectedDetection, setSelectedDetection] = useState<DetectionData>(DetectionDefault)
    const [detectionTotalCount, setDetectionTotalCount] = useState<number>(0);
    const [detectionPageCount, setDetectionPageCount] = useState<number>(0);
    const [filterValue, setFilterValue] = useState<FilterValue>(getDefaultFilterValue());
    const [dateFilterValue, setDateFilterValue] = useState<DateFilterValue>(getDefaultDateFilterValue())
    const [showCaputreImagesModal, setShowCaptureImagesModal] = useState<boolean>(false)
    const [includeNonDetections, setIncludeNonDetections] = useState<boolean>(false);
    const [tableOffset, setTableOffset] = useState(0);
    const [tablePage, setTablePage] = useState(1);
    const [showReadMoreModal, setShowReadMoreModal] = useState(false)
    const [remark, setRemark] = useState("");

    const [errorText, setErrorText] = useState("");
    const [successText, setSuccessText] = useState("");
    const [intervalToggle, setIntervalToggle] = useState(true)
    const [isLoading, setIsLoading] = useState(false)

    // for fetching capture images
    const captureTime = addSecondToDate(selectedDetection?.captureTime, CAPTURE_ADJUST_TIME_WITH_DETECTION_IN_SECOND)?.toString() || ""
    const startDate = convertToLocaleDate(captureTime)?.toString() || ""
    const endDate = addSecondToDate(startDate || "", CAPTURE_END_RANGE_FOR_DETECTION_PAGE_IN_SECOND)?.toString() || ""
    const captureDataLimitObj = {
        machineId: selectedDetection?.machineId!,
        startDate: startDate,
        endDate: endDate
    }

    const {images: captureImages, totalCount: captureCount} = useCaptureImages(captureDataLimitObj, [showCaputreImagesModal])

    const dayRange = getDayDifference(dateFilterValue.startDate, dateFilterValue.endDate);

    useEffect(() => {
        if (!errorText) {
            setTimeout(() => {
                setIntervalToggle(prev => !prev); 
            }, 5000)
        }
    }, [intervalToggle])

    useEffect(() => {
        setErrorText("")
        setSuccessText("")
        if (hasPermission("viewDetection")) {
            getDetectionList()
        }
    }, [intervalToggle])

    useEffect(() => {
        setTablePage(1)
        setTableOffset(0)
    }, [filterValue, dateFilterValue, includeNonDetections])

    useEffect(() => {
        setErrorText("")
        setSuccessText("")
        if (hasPermission("viewDetection")) {
            setIsLoading(true)
            getDetectionList()
        }
    }, [filterValue, dateFilterValue, includeNonDetections, tableOffset]);

    useEffect(() => {
        if (tablePage > detectionPageCount && detectionPageCount !== 0) {
            setTablePage(detectionPageCount)
            const offsetValue = (tableLimit * (detectionPageCount - 1));
            setTableOffset(offsetValue);
        }
    }, [detectionPageCount])

    useEffect(() => {
        setFirstCaptureImages([])
        if (detectionList.length > 0) {
            detectionList.forEach(detection => getFirstCaptureImageOfDetection(detection));
        }
    }, [detectionList]);

    const handleChangeFilterValue = (event: ChangeEvent<HTMLSelectElement>) => {
        setFilterValue(
            (prevValue) => {
                return {
                    ...prevValue,
                    [event.target.name]: event.target.value,
                }
            }
        )

        if (event.target.name === "organizationId") {
            setFilterValue(
                (prevFilterData) => {
                    return  {
                        ...prevFilterData,
                        machineId: 0,
                    }
                }
            )
        }

        if (event.target.name === "organizationId" || event.target.name === "machineId") {
            setFilterValue(
                (prevFilterData) => {
                    return  {
                        ...prevFilterData,
                        tenantId: 0,
                    }
                }
            )
        }
    }

    const handleChangeIncludeNonDetections = (event: ChangeEvent<HTMLInputElement>) => {
        setIncludeNonDetections(event.target.checked)
    }

    const handleChangeDateFilterValue = (key: "startDate" | "endDate", value: Date) => {
        const day = value.getDate().toString().padStart(2, '0');
        const month = (value.getMonth() + 1).toString().padStart(2, '0');
        const year = value.getFullYear();
        const formattedDate = `${year}-${month}-${day}`;
        setDateFilterValue({...dateFilterValue, [key]: formattedDate})
    }

    const handleChangePage = (event : ChangeEvent<unknown>, pageValue : number) => {
        setTablePage(pageValue);
        if (pageValue === 1) {
            setTableOffset(0);
        }
        else{
            const offsetValue = (tableLimit * (pageValue - 1));
            setTableOffset(offsetValue);
        }
    };

    const handleClickReadmore = (remark: string) => {
        setShowReadMoreModal(true)
        setRemark(remark)
    }

    const handleClickClose = () => {
        setShowReadMoreModal(false)
        setRemark("")
    }

    const exportToPdfWithImage = async () => {
        setIsLoading(true)
        let exportedData = await getExportedData()
        await generatePdfWithImage(exportedData)
        setIsLoading(false)
        const log: string = `${userInfo.userName} has downloaded detections-with-images-${getCurrentLocaleDate()}.pdf`
        addEventLog(
            log,
            "",
            LOG_LEVEL.INFO
        )
        addNormalLog(log, LOG_LEVEL.INFO)
    }

    const getExportedData = async() => {
        let exportedData = []
        try {
            if (isTenantUser()) {
                exportedData = await getDetectionExportListByUserId(userInfo.id || 0, filterValue.machineId!)
            } else {
                exportedData = await getDetectionExportListByOrgId(filterValue.organizationId!, filterValue.machineId!, filterValue.tenantId!)
            }
        } catch(e) {
            console.log(e)
            exportedData = []
        }
        return exportedData
    }

    const getDetectionList = () => {
        if (isTenantUser()) {
            getDetectionListByUserId(userInfo.id || 0, filterValue.machineId!, tableLimit, tableOffset);
        } else {
            getDetectionListByOrgId(filterValue.organizationId!, filterValue.machineId!, filterValue.tenantId!, tableLimit, tableOffset);
        }
    }

    const getDetectionListByOrgId = (orgId: number, machineId: number, tenantId: number, pageLimit : number, pageOffset : number) => {
        const dataLimitsObject: DetectionGETRequestBody = {
            orgId: orgId,
            machineId: machineId,
            tenantId: tenantId,
            startDate: convertDateFormat(dateFilterValue?.startDate || ""),
            endDate: convertDateFormat(dateFilterValue?.endDate || ""),
            limit: pageLimit,
            offset: pageOffset,
            includeNonDetections: includeNonDetections,
        }
        detectionGetListByOrgIdApi(dataLimitsObject)
        .then(response => {
            const resData: DetectionListResponseBody = response?.data
            if (resData?.status === STATUS_CODES.SUCCESS_CODE) {
                const dataList: DetectionData[] = resData.data?.detectionList
                const totalCount : number = resData?.data?.totalCount;
                setDetectionTotalCount(totalCount)
                setDetectionPageCount((Math.ceil(totalCount/tableLimit)) || 0);
                setDetectionList(dataList);
            }
            else {
                setErrorText(resData?.message)
                console.log ('error');
            }
            setIsLoading(false)
        })
        .catch(error => {
            setSuccessText("");
            setErrorText(error?.response?.data?.message || error?.message)
            setIsLoading(false)
        })
    } 
    
    const getDetectionListByUserId = (userId: number, machineId: number, pageLimit : number, pageOffset : number) => {
        const dataLimitsObject: DetectionGETRequestBody = {
            userId: userId,
            machineId: machineId,
            startDate: convertDateFormat(dateFilterValue?.startDate || ""),
            endDate: convertDateFormat(dateFilterValue?.endDate || ""),
            limit: pageLimit,
            offset: pageOffset,
            includeNonDetections: includeNonDetections,
        }
        detectionGetListByUserIdApi(dataLimitsObject)
        .then(response => {
            const resData: DetectionListResponseBody = response?.data
            if (resData?.status === STATUS_CODES.SUCCESS_CODE) {
                const dataList: DetectionData[] = resData.data?.detectionList
                const totalCount : number = resData?.data?.totalCount;
                setDetectionTotalCount(totalCount)
                setDetectionPageCount((Math.ceil(totalCount/tableLimit)) || 0);
                setDetectionList(dataList);
            }
            else {
                setErrorText(resData?.message)
                console.log ('error');
            }
            setIsLoading(false)
        })
        .catch(error => {
            setSuccessText("");
            setErrorText(error?.response?.data?.message || error?.message)
            setIsLoading(false)
        })
    }  

    const getFirstCaptureImageOfDetection = (detection: DetectionData) => {
        const input = {
            machineId: detection.machineId, 
            captureTime: convertDateFormatISO8061(detection.captureTime)|| ""
        }
        getFirstCaptureImageOfDetectionApi(input)
        .then(response => {
            const resData = response?.data
            if (resData?.status === STATUS_CODES.SUCCESS_CODE) {
                setFirstCaptureImages(prevs => ({
                    ...prevs,
                    [detection.id!]: resData?.data
                }))
            }
        }).catch(error => {
            setSuccessText("");
            setErrorText(error?.response?.data?.message || error?.message)
        })
    }

    const getDetectionExportListByOrgId = async (orgId: number, machineId: number, tenantId: number): Promise<any> => {
        const dataLimitsObject: DetectionGETRequestBody = {
            orgId: orgId,
            machineId: machineId,
            tenantId: tenantId,
            startDate: convertDateFormat(dateFilterValue?.startDate || ""),
            endDate: convertDateFormat(dateFilterValue?.endDate || ""),
            includeNonDetections: includeNonDetections,
        }
        try {
            const response: AxiosResponse<DetectionListResponseBody> = await detectionGetExportListByOrgIdApi(dataLimitsObject);
            const resData: DetectionListResponseBody = response.data;
        
            if (resData?.status === STATUS_CODES.SUCCESS_CODE) {
              const dataList: DetectionData[] = resData.data?.detectionList;
              return dataList;
            } else {
              console.log(resData?.message);
              return [];
            }
        } catch (error: any) {
            console.log('Error fetching data:', error?.message);
            return [];
        }
    }

    const getDetectionExportListByUserId = async (userId: number, machineId: number): Promise<any> => {
        const dataLimitsObject: DetectionGETRequestBody = {
            userId: userId,
            machineId: machineId,
            startDate: convertDateFormat(dateFilterValue?.startDate || ""),
            endDate: convertDateFormat(dateFilterValue?.endDate || ""),
            includeNonDetections: includeNonDetections,
        }
        return detectionGetExportListByUserIdApi(dataLimitsObject)
        .then(response => {
            const resData: DetectionListResponseBody = response?.data
            return new Promise((resolve, reject) => {
                if (resData?.status === STATUS_CODES.SUCCESS_CODE) {
                    const dataList: DetectionData[] = resData.data?.detectionList
                    resolve(dataList)
                }
                else 
                    reject([])
            })
        })
        .catch(error => {
            console.log(error?.message)
            return Promise.reject([])
        })
    }  

    const renderTable = () => {
        return (
            detectionList?.length !== 0 ? detectionList?.map((detection: DetectionData) => {    
                return (
                <tr key={detection.id}>
                    <td>{detection.id}</td>
                    <td style={{minWidth: "350px"}}>
                        <ModalImage
                            small={mergeImageUrlWithBaseUrl(detection?.nonOrganicImageUrl) || NO_IMAGE_URL}
                            large={mergeImageUrlWithBaseUrl(detection?.nonOrganicImageUrl) || NO_IMAGE_URL}
                            alt="Non Organic Image"
                            className="table-img"
                        />
                    </td>
                    <td style={{minWidth: "350px"}}>
                        <ImageModalButton 
                            title="Capture Images"
                            thumbnail={mergeImageUrlWithBaseUrl(firstCaptureImages[detection.id!]?.imageUrl) || NO_IMAGE_URL}
                            images={captureImages}
                            count={captureCount}
                            buttonType="image"
                            handleShow={setShowCaptureImagesModal}
                            show={showCaputreImagesModal && detection.id === selectedDetection.id}
                            onOpen={() => setSelectedDetection(detection)}
                            onClose={() => setSelectedDetection(DetectionDefault)}
                        />
                    </td> 
                    <td>{convertToLocaleDate(detection.captureTime!) || "-"}</td>
                    {/* <td>
                    <ModalImage
                        small={`data:image/jpeg;base64,${detection.organicImgPath || NOImage}`}
                        large={`data:image/jpeg;base64,${detection.organicImgPath || NOImage}`}
                        alt="Organic Image"
                        className="table-img"
                        />
                    </td> */}
                   
                    <td>{detection.organizationName || "-"}</td>
                    <td>{detection.tenantName || "-"}</td>
                    <td>{detection.machineName || "-"}</td>
                    <td>
                        {
                            detection.remark ?
                            <div className='d-flex align-items-center'>
                                <p style={{overflow: "hidden", width: "250px"}} className="m-0">{detection.remark}</p>
                                {
                                    (detection?.remark?.split(" ").length > 5) &&
                                    <button onClick={() => handleClickReadmore(detection.remark!)} className='btn btn-outline-light text-primary'>more...</button>
                                }
                            </div>:
                            "-"
                        }
                    </td>
                    { isSuperSpecialUser() &&
                        <td>{convertToLocaleDate(detection.createdAt!) || "-"}</td>
                    }
                </tr>)
            }): 
            <tr>
                <td>No data found</td>
            </tr>
        )
    }

    if (!hasPermission("viewDetection")) {
        return (    
            <Layout>
                <Container>
                    <UnauthorizedPage />
                </Container>
            </Layout>
        )
    }

    return (
        <>
            <Layout>
                <Container>
                    <div className="row">
                        <div className="col-lg-10 col-md-12">
                            <DetectionFilterContainer
                                onChangeFilter={handleChangeFilterValue}
                                onChangeDateFilter={handleChangeDateFilterValue}
                                filterValue={filterValue}
                                dateFilterValue={dateFilterValue}
                                userInfo={userInfo}
                                onChangeIncludeNonDetections={handleChangeIncludeNonDetections}
                            />
                        </div>
                        <div className="col-lg-2 col-md-12">
                            <Box className="titleBox">
                                <Button onClick={exportToPdfWithImage} disabled={dayRange > 31}><PictureAsPdfIcon /></Button>
                            </Box>
                        </div>
                    </div>
                    
                    {successText && <Alert severity="success">{successText}<br/></Alert>}
                    {errorText && <Alert severity="error" sx={{marginBottom: 2}}>{errorText}</Alert>}

                    <SummaryText>{detectionTotalCount} Detection(s) found!</SummaryText>
                    <TableContainer>
                    <InnterTableContainer>
                        <table className="styled-table">
                            <thead className="table-header">
                                <tr>
                                    <th>ID</th>
                                    <th>Non Organic Image</th>
                                    <th>Capture Image</th>
                                    <th>Capture Time</th>
                                    <th>Organization</th>
                                    <th>Tenant</th>
                                    <th>Machine</th>
                                    <th>Remark</th>
                                    { isSuperSpecialUser() &&
                                        <th>Created At</th>
                                    }
                                </tr>
                            </thead>
                            <tbody className="table-data">
                                {
                                    renderTable()
                                }
                            </ tbody>
                        </table>
                    </InnterTableContainer>
                    </TableContainer>
                    <ReadMoreModal 
                        show={showReadMoreModal}
                        handleClickClose={handleClickClose}
                        bodyText={remark}
                    />
                    {
                        isLoading &&
                        <LoadingModal 
                            show={isLoading}
                        />
                    }
                    <PaginationContainer>
                        <Pagination 
                            count={detectionPageCount} 
                            onChange={handleChangePage} 
                            page={tablePage} 
                            variant="outlined" 
                            shape="rounded" size="large" 
                            disabled={isLoading}
                        />
                    </PaginationContainer>
                </Container>
            </Layout>
        </>
    )
};

export default Detection;