export type MachinesData = {
    id?: number;
    machineUuid?: string;
    organizationId: number;
    organizationName?: string;
    carbonReductionFactor?: number;
    locationId: number;
    locationName?: string;
    machineName: string;
    machineType: string;
    lfcSerialNumber: string;
    detectWeight: number;
    isSyncExternalNfc: boolean;
    isGeneralWaste: boolean;
    enableSyncWithSource: boolean;
    remark: string;
    status: string;
    createdBy?: string;
    updatedBy?: string;
    deletedBy?: string;
    createdAt?: string;
    updatedAt?: string;
    deletedAt?: string;
}
  
export type MachineListResponseBody = {
    data: {
      machineList: MachinesData[],
      totalCount: number,
    }
    status: number,
    message: string,
}
  
export type MachineResponseBody = {
    data: MachinesData,
    status: number,
    message: string,
}
  
export type MachineGETRequestBody = {
    limit: number,
    offset: number
}

export type MachineGETByOrgIdRequestBody = {
    searchText: string,
    orgId: number,
    limit?: number,
    offset?: number
}

export type MachineADDRequestBody = {
    organizationId: number;
    locationId: number;
    machineName: string,
    machineType: string,
    lfcSerialNumber: string,
    detectWeight: number,
    isSyncExternalNfc: boolean,
    enableSyncWithSource: boolean,
    remark: string,
    createdBy: string,
}
  
export type MachineDELETERequestBody = {
    id: number,
    deletedBy: string,
}
  
export type MachineUPDATERequestBody = {
    id: number,
    organizationId: number;
    locationId: number;
    machineName: string,
    machineType: string,
    lfcSerialNumber: string,
    detectWeight: number,
    isSyncExternalNfc: boolean,
    enableSyncWithSource: boolean,
    remark: string,
    updatedBy: string,
}

export const MachineDefault: MachinesData = {
    id: 0,
    organizationId: 0,
    organizationName: "",
    locationId: 0,
    locationName: "",
    machineName: "",
    machineType: "",
    lfcSerialNumber: "",
    detectWeight: 0,
    remark: "",
    isSyncExternalNfc: false,
    isGeneralWaste: false,
    enableSyncWithSource: false,
    status: "",
}
    