import { 
    BarChartWeightRequestType, 
    TotalWeightByYearRequestType, 
    BarChartWeightByDayRequestType } from "../../types/NewDashboardType";
import http from "../apiServices";

export const getTotalWeightApi = (requestBody: BarChartWeightRequestType) => {
    return http.post("/dashboard/get/total-weight", requestBody);
}

export const getWeightApi = (requestBody: BarChartWeightRequestType, url: String) => {
    return http.post("/dashboard/get/weight/by/"+ url, requestBody)
}
export const getWeightApiByWasteTypeApi = (requestBody: BarChartWeightRequestType) => {
    return http.post("/dashboard/get/weight/by/waste-type", requestBody)
}

export const getWeightByYearApi = (requestBody: TotalWeightByYearRequestType) => {
    return http.post("/dashboard/get/weight/by/year", requestBody)
}

export const getWeightByStartDateEndDateApi = (requestBody: BarChartWeightRequestType) => {
    return http.post("/dashboard/get/weight/by/start-date-and-end-date", requestBody)
}

export const getWeightByDayApi = (requestBody: BarChartWeightByDayRequestType) => {
    return http.post("/dashboard/get/weight/by/day", requestBody)
}

