import React from "react";
import PlasticImage from "../../assets/images/plastic_recycle_icon.png"; 

const PlasticIcon = () => {
    return (
        <div>
            <img 
                src={PlasticImage} 
                alt="Plastic Icon" 
                style={{ width: "25px", height: "25px" }}
            />
        </div>
    );
}

export default PlasticIcon;
