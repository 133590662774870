import { WasteUploadGETRequestBody, WasteUploadUpdateRequestBody, WasteUploadADDRequestBody } from "../../types/WasteUploadType";
import http from "../apiServices";

export const wasteUploadGetListByOrgIdApi = (inputData : WasteUploadGETRequestBody) => {
    return http.post("/waste-upload/get/list/by/orgId", inputData)
}

export const wasteUploadGetListByUserIdApi = (inputData : WasteUploadGETRequestBody) => {
    return http.post("/waste-upload/get/list/by/userId", inputData)
} 

export const wasteUploadAddApi = (inputData : WasteUploadADDRequestBody) => {
    return http.post("/waste-upload/add", inputData)
}

export const wasteUploadDeleteApi = (inputData : {id: number, deletedBy: string}) => {
    return http.post("/waste-upload/delete", inputData)
}

export const wasteUploadUpdateApi = (inputData : WasteUploadUpdateRequestBody) => {
    return http.post("/waste-upload/update", inputData)
}