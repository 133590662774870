import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { getCurrentLocaleDate, convertToLocaleDate, getMonthName } from "../utils/DateTimeUtil";
import logoImage from '../assets/logo/env-logo.png';
import { addEventLog } from "../utils/EventLogUtil";
import { addNormalLog } from "../utils/LoggerUtil";
import { LOG_LEVEL, PAGE_LIMIT, STATUS_CODES } from "../constants/GlobalConstants";
import XLSX from "xlsx";

export const generatePDF = (
    headers: string[], 
    dataKeys: string[], 
    columnWidths: number[], 
    dataList: any[], 
    dateTimeColumns: string[], 
    totalWeightAmount: number, 
    text: string, 
    title: string, 
    fileName: string,
    userName: string,
    fontSize: number,
    isSetBodyCellColor: boolean) => {
    const doc = new jsPDF('landscape', 'mm', 'a4');

    const columnStyles: any = {};
    for (let i = 0; i < headers.length; i++) {
        if(columnWidths.length > 0) {
            columnStyles[dataKeys[i]] = { cellWidth: columnWidths[i] };
        }
    }

    autoTable(doc, {
        head: [headers],
        body: dataList.map((data) => 
            dataKeys.map((key) => 
            dateTimeColumns.includes(key) 
                ? convertToLocaleDate(data[key])
                : data[key] !== null && data[key] !== undefined 
                ? data[key]
                : "-"
            )
        ), 
        columns: dataKeys.map((key) => ({ dataKey: key })),
        columnStyles: columnStyles,
        margin: { top: 25, left: 5},
        didParseCell: function (data) {
            if (data.section === "head") {
              data.cell.styles.fontSize = fontSize;
              data.cell.styles.textColor = [255, 255, 255];
              data.cell.styles.fillColor = [0, 0, 0];
            }
            else if (isSetBodyCellColor && data.section === "body") {
                if (data.column.dataKey === "lfcType") {
                    const textColor = getTextColorForDoorLog(data)
                    data.cell.styles.textColor = textColor
                }
                else if(data.column.dataKey === "level"){
                    const textColor = getTextColorForMachineLog(data)
                    data.cell.styles.textColor = textColor
                }
                else if(data.column.dataKey === "eventLevel"){
                    const textColor = getTextColorForOperatorLog(data)
                    data.cell.styles.textColor = textColor
                }
            }
        },
        didDrawPage: function (data) {
            doc.setFontSize(18);
            // doc.text("Weight Log", data.settings.margin.left, 10);
            doc.text(title, data.settings.margin.left, 10);

            const logoWidth = 20;
            const logoHeight = 10;
            const logoX = data.settings.margin.left + 250;
            const logoY = 5;
            doc.addImage(logoImage, 'PNG', logoX, logoY, logoWidth, logoHeight);

            doc.setFontSize(13);
            if(totalWeightAmount && text){
                doc.text(`${text} ${totalWeightAmount || 0} kg`, data.settings.margin.left, 20);
            }
            if(!totalWeightAmount && text){
                doc.text(`${text}`, data.settings.margin.left, 20);
            }
        }
    })
    // doc.save(`weightlogs-${getCurrentLocaleDate()}.pdf`)
    const saveFileName = `${fileName}-${getCurrentLocaleDate()}.pdf`;
    doc.save(saveFileName)
    const log: string = `${userName} has downloaded ${saveFileName}`
    addEventLog(
        log,
        "",
        LOG_LEVEL.INFO
    )
    addNormalLog(log, LOG_LEVEL.INFO)
}

export const generateCSV = async (headerToKeyMap: { [key: string]: string }, dataList: any[], dateTimeColumns: string[], sheetName: string, fileName: string, userName : string ) => { 
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(loadExportList(headerToKeyMap, dataList, dateTimeColumns));
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    const saveFileName = `${fileName}-${getCurrentLocaleDate()}.xlsx`;
    XLSX.writeFile(workbook, saveFileName);
    const log: string = `${userName} has downloaded ${saveFileName}`
    addEventLog(
        log,
        "",
        LOG_LEVEL.INFO
    )
    addNormalLog(log, LOG_LEVEL.INFO)
};

export const generateSpecialPDF = async (
    dailyDataList: any[], monthlyDataList: any[], weightChartData: any[],
    doorOpenChartData: any[], doorScanChartData: any[],
    year: number, month: number, machine: string, organization: string,
    fileName: string, userName: string 
  ) => {
    const doc = new jsPDF('landscape', 'mm', 'a3');
  
    const table1Headers = [
        "TENANTS", "MONTHLY DATA"
    ];
    const table1DataKeys = [
        "tenant", "weightAmount"
    ];
  
    addTable(
      doc, "Monthly Data", table1Headers, table1DataKeys, monthlyDataList,
      machine, organization, year, month,
      65, 50
    )
  
    doc.addPage();
  
    let table2Columns = Object.keys(dailyDataList[0]).sort(customDateSort)
    const table2Headers = table2Columns.slice(0, table2Columns.length)
                          .map(column => column.split("-")[2])
    const table2DataKeys = table2Columns
  
    addTable(
      doc, "Daily Data", table2Headers, table2DataKeys, dailyDataList,
      machine, organization, year, month,
      55, 3.5
    )
  
    doc.addPage();
  
    addAnnotations(doc, machine, organization, year, month, 20, 15)
    addChart(doc, monthlyDataList[0]?.weightAmount, weightChartData, "weight")
  
    doc.addPage();
  
    addAnnotations(doc, machine, organization, year, month, 20, 15)
    addChart(doc, monthlyDataList[1]?.weightAmount, doorOpenChartData, "open")
  
    doc.addPage();
  
    addAnnotations(doc, machine, organization, year, month, 20, 15)
    addChart(doc, monthlyDataList[2]?.weightAmount, doorScanChartData, "scan")
  
    const saveFileName = `${fileName}-${getCurrentLocaleDate()}.pdf`;
    doc.save(`special-${saveFileName}`)
    const log: string = `${userName} has downloaded ${fileName}`
    addEventLog(
        log,
        "",
        LOG_LEVEL.INFO
    )
    addNormalLog(log, LOG_LEVEL.INFO)
}

const addAnnotations = (
    doc: jsPDF, machine: string, organization: string, 
    year: number, month: number, x = 20, y=40
) => {
    doc.setFontSize(15);
    doc.text(`Machine: ${machine}`, x, y);
    x += doc.getTextWidth(`Machine: ${machine}`) + 10; // Add padding

    doc.text(`Organization: ${organization}`, x, y);
    x += doc.getTextWidth(`Organization: ${organization}`) + 10;

    doc.text(`Year: ${year}`, x, y);
    x += doc.getTextWidth(`Year: ${year}`) + 10;

    doc.text(`Month: ${getMonthName(month)}`, x, y);
}

const customDateSort = (a: string, b: string): number => {
    // Skip the 'name' entry
    if (a === 'Name' || b === 'Name') {
      return a === 'Name' ? -1 : 1;
    }
  
    const dateA = new Date(a);
    const dateB = new Date(b);
  
    return dateA.getTime() - dateB.getTime();
};
  
const addTable = (
    doc: jsPDF, type: string, tableHeaders: string[], tableDataKeys: string[],
    dataList: any[],
    machine: string, organization: string, year: number, month: number,
    x: number, y: number) => {

    autoTable(doc, {
        head: [tableHeaders],
        body: dataList,
        columns: tableDataKeys.map((key) => ({ dataKey: key })),
        margin: { top: x, left: y},
        didParseCell: function (data) {
            data.cell.styles.fontSize = 12.5
            if (data.section === "head") {
            data.cell.styles.textColor = [255, 255, 255];
            data.cell.styles.fillColor = [0, 0, 0];
            }

            if (data.cell.raw === null || data.cell.raw === "" || data.cell.raw === undefined) {
            data.cell.text = ["0"];
            }
        },
        didDrawPage: function (data) {
            doc.setFontSize(18);
            doc.text(type, 10, 10);

            const logoWidth = 20;
            const logoHeight = 10;
            const logoX = 130 + 250;
            const logoY = 5;
            doc.addImage(logoImage, 'PNG', logoX, logoY, logoWidth, logoHeight);

            addAnnotations(doc, machine, organization, year, month)
        }
    })}
  
    const addChart = (
        doc: jsPDF, totalValue: number, chartData: any[], type: "weight" | "open" | "scan") => {
    doc.setFontSize(18);
    if (type === "weight") {
        doc.text(`Total Weight: ${totalValue}kg`, 20, 30);
    } else if (type === "open") {
        doc.text(`Total Door Open: ${totalValue}`, 20, 30);
    } else if (type === "scan") {
        doc.text(`Total Door Scan: ${totalValue}`, 20, 30);
    }

    const chartX = 30;
    const chartY = 80;
    const chartWidth = 390;
    const chartHeight = 150;

    // Calculate the maximum value to scale the chart
    const maxScanDataValue = Math.max(...chartData.map(item => item.value));
    const scanScaleFactor = maxScanDataValue != 0 ? chartHeight / maxScanDataValue : 0;

    // Y-axis label
    doc.setFontSize(15);
    if (type === "weight") {
        doc.text('Weight (kg)', chartX - 23, chartY + chartHeight / 2, undefined, 90);
    } else {
        doc.text('Count', chartX - 23, chartY + chartHeight / 2, undefined, 90);
    }

    // X-axis label
    doc.text('Date', chartX + chartWidth / 2, chartY + chartHeight + 20);

    // Add Y-axis values and tick marks
    for (let i = 0; i <= maxScanDataValue; i += 10) {
        const y = chartY + chartHeight - i * scanScaleFactor;
        doc.text(i.toString(), chartX - 18, y + 3);
        doc.setDrawColor(100, 100, 100);
        doc.line(chartX - 8, y, chartX - 3, y);
    }

    // Draw the bars on the chart
    chartData.forEach((item, index) => {
        const barX = chartX + index * (chartWidth / chartData.length);
        const barY = chartY + chartHeight - item.value * scanScaleFactor;
        const barWidth = chartWidth / chartData.length - 2;
        const barHeight = item.value * scanScaleFactor;

        if (type === "weight") {
        doc.setFillColor(209, 77, 114); // Set the bar color
        } else if (type === "open") {
        doc.setFillColor(25, 167, 206); // Set the bar color
        } else if (type === "scan") {
        doc.setFillColor(255, 184, 76); // Set the bar color
        }
        doc.rect(barX, barY, barWidth, barHeight, 'F'); // Draw the bar
        doc.text(item.label, barX, chartY + chartHeight + 10);

        if (item.value > 0) {
        doc.text(
            item.value.toString(),
            barX + barWidth / 2, // Adjust the position as needed
            barY - 5, // Adjust the position as needed,
            undefined,
            90
        );
        }
    });
}

const loadExportList = (
    headerToKeyMap: { [key: string]: string },
    dataList: any[],
    dateTimeColumns: string[]
  ) => {
    if (!dataList || !Array.isArray(dataList)) return [];
  
    const headers = Object.keys(headerToKeyMap);
    return dataList.map(data => {
      const result: { [key: string]: any } = {};
  
      headers.forEach(header => {
        const key = headerToKeyMap[header];
        if (key) {
          result[header] = dateTimeColumns.includes(key)
            ? convertToLocaleDate(data[key])
            : data[key] !== null && data[key] !== undefined
              ? data[key]
              : "-";
        }
      });
      return result;
    });
  };
  
  const getTextColorForMachineLog = (data: any): [number, number, number] => {
    const machineLogLevel = data.row.raw.level;
    if ( machineLogLevel === LOG_LEVEL.WARN ) {
        return [235, 121, 14];
    } else if (machineLogLevel === LOG_LEVEL.ALERT ) {
        return [235, 14, 21];
    } else if (machineLogLevel === LOG_LEVEL.SUCCESS) {
        return [83, 145, 101]
    }  else {
        return [0, 0, 0]
    }
};

const getTextColorForDoorLog = (data: any): [number, number, number] => {
    const lfcType = data.row.raw.lfcType;
    console.log("lfcType");
    console.log(lfcType);
    if (lfcType === "open" ) {
        return [235, 14, 21];
    } else if (lfcType === "close") {
        return [83, 145, 101]
    }  else {
        return [0, 0, 0]
    }
};

const getTextColorForOperatorLog = (data: any): [number, number, number] => {
    const logLevel = data.row.raw.eventLevel;
    if (logLevel === LOG_LEVEL.WARN ) {
        return [235, 121, 14];
    } else if (logLevel === LOG_LEVEL.ALERT ) {
        return [235, 14, 21];
    } else if (logLevel === LOG_LEVEL.SUCCESS) {
        return [83, 145, 101]
    }  else {
        return [0, 0, 0]
    }
};

const getTextColor = (data: any): [number, number, number] => {
    const logLevel = data.row.raw.eventLevel;
    if (logLevel === LOG_LEVEL.WARN ) {
        return [235, 121, 14];
    } else if (logLevel === LOG_LEVEL.ALERT ) {
        return [235, 14, 21];
    } else if (logLevel === LOG_LEVEL.SUCCESS) {
        return [83, 145, 101]
    }  else {
        return [0, 0, 0]
    }
};
