import { Button, Modal } from "react-bootstrap";
import { CurrentWasteUpload } from "../../../types/WasteUploadType";

type Props = {
    show: boolean,
    wasteUpload: CurrentWasteUpload
    toggleShow: () => void
    onDelete: () => void
}

const DeleteModal = (props: Props) => {
    const {show, wasteUpload, toggleShow, onDelete} = props
    return (  
        <Modal show={show}>
            <Modal.Header>
                <Modal.Title>Delete Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to delete <strong>[Id: {wasteUpload.id}]</strong>?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={toggleShow}>Cancel</Button>
                <Button variant="danger" onClick={onDelete}>Delete</Button>
            </Modal.Footer>
        </Modal>
    );
}
 
export default DeleteModal;