import http from "../apiServices";
import { UserInTenantADDRequestBody, UserInTenantDELETERequestBody } from "../../types/UserInTenantType";

export const getTenantsByUserIdApi = (inputData : {userId: number}) => {
    return http.post("/user-in-tenant/get/tenants/by-userId", inputData)
}

export const userInTenantAddDataApi = (inputData : UserInTenantADDRequestBody) => {
    return http.post("/user-in-tenant/add", inputData)
}

export const userInTenantDeleteDataApi = (inputData: UserInTenantDELETERequestBody) => {
    return http.post("/user-in-tenant/delete", inputData)
}

export const userInTenantUpdateDataApi = (inputData: any) => {
    return http.put("/user-in-tenant/update", inputData)
}