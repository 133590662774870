import { Alert, Box, Pagination } from "@mui/material";
import Layout from "../Layout/Layout";
import { CardsContainer, Container, PaginationContainer } from "../Shared/Common/Containers";
import { SummaryText, Title } from "../Shared/Common/Titles";
import LoadingModal from "../Shared/LoadingModal/LoadingModal";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { UserAuthType, UserInfo } from "../../types/UserAuth";
import { UserAuthContext } from "../../contexts/UserAuthContext";
import UnauthorizedPage from "../Shared/ErrorPages/UnauthorizedPage";
import { getCurrentLocalUser, isAdminUser, isTenantUser } from "../../utils/UserUtil";
import {  PAGE_WITH_IMG_LIMIT, STATUS_CODES } from "../../constants/GlobalConstants";
import { convertDateFormat } from "../../utils/DateTimeUtil";
import { DateFilterValue } from "../../types/global/FilterValueType";
import { getDefaultDateFilterValue, getDefaultFilterValue } from "../../utils/DefaultFilterValueUtil";
import WasteUploadFilterContainer from "./FilterContainer";
import { wasteUploadAddApi, wasteUploadUpdateApi, wasteUploadDeleteApi, wasteUploadGetListByOrgIdApi, wasteUploadGetListByUserIdApi } from "../../services/RouteServices/WasteUploadApi";
import { WasteUploadData, WasteUploadListResponseBody, WasteUploadFilterValue, WasteUploadGETRequestBody, CurrentWasteUpload } from "../../types/WasteUploadType";
import { Button } from "react-bootstrap";
import AddModal from './components/AddModal';
import DeleteModal from './components/DeleteModal';
import EditModal from './components/EditModal';
import SuccessToastify from '../Shared/Toastify/SuccessToastify';
import ErrorToastify from '../Shared/Toastify/ErrorToastify';
import WasteUploadCard from "./WasteUploadCard";

const WasteUpload = () => {
    const tableLimit = PAGE_WITH_IMG_LIMIT;
    const userInfo: UserInfo = getCurrentLocalUser();
    const { hasPermission } = useContext(UserAuthContext) as UserAuthType

    const [wasteUplaodList, setWasteUploadList] = useState<WasteUploadData[]>([]);
    const [detectionTotalCount, setDetectionTotalCount] = useState<number>(0);
    const [tableOffset, setTableOffset] = useState(0);
    const [tablePage, setTablePage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false)
    const [remark, setRemark] = useState("");
    const [filterValue, setFilterValue] = useState<WasteUploadFilterValue>({...getDefaultFilterValue(), type: "all"});
    const [dateFilterValue, setDateFilterValue] = useState<DateFilterValue>(getDefaultDateFilterValue())
    const [totalWeight, setTotalWeight] = useState<number | undefined>(0);
    const [errorText, setErrorText] = useState("");
    const [successText, setSuccessText] = useState("");
    const [showAddModal, setShowAddModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [currentWasteUpload, setCurrentWasteUpload] = useState<CurrentWasteUpload>({} as CurrentWasteUpload);

    useEffect(() => {
        setTablePage(1)
        setTableOffset(0)
    }, [filterValue, dateFilterValue])

    useEffect(() => {
        setErrorText("")
        setSuccessText("")
        if (hasPermission("viewWasteUpload")) {
            setIsLoading(true)
            getWasteUploadList()
        }
    }, [filterValue, dateFilterValue, tableOffset]);

    const getWasteUploadList = () => {
        if (isTenantUser()) {
            getWasteTypeListByUserId();
        } else {
            getWasteUploadListByOrgId();
        }
    }

    const getWasteUploadListByOrgId = () => {
        const dataLimitsObject: WasteUploadGETRequestBody = {
            type: filterValue.type,
            orgId: filterValue.organizationId!,
            machineId: filterValue.machineId!,
            tenantId: filterValue.tenantId!,
            startDate: convertDateFormat(dateFilterValue?.startDate || ""),
            endDate: convertDateFormat(dateFilterValue?.endDate || ""),
            limit: tableLimit,
            offset: tableOffset,
        }
        wasteUploadGetListByOrgIdApi(dataLimitsObject)
        .then(response => {
            const resData: WasteUploadListResponseBody = response?.data
            if (resData?.status === STATUS_CODES.SUCCESS_CODE) {
                const dataList: WasteUploadData[] = resData.data?.wasteUploadList
                const totalCount : number = resData?.data?.totalCount;
                setDetectionTotalCount(totalCount)
                setTotalWeight(resData?.data?.totalWeight)
                setPageCount((Math.ceil(totalCount/tableLimit)) || 0);
                setWasteUploadList(dataList);
            }
            else {
                setErrorText(resData?.message)
                console.log ('error');
            }
            setIsLoading(false)
        })
        .catch(error => {
            setSuccessText("");
            setErrorText(error?.response?.data?.message || error?.message)
            setIsLoading(false)
        })
    } 

    const getWasteTypeListByUserId = () => {
        const dataLimitsObject: WasteUploadGETRequestBody = {
            type: filterValue.type,
            userId: userInfo.id,
            machineId: filterValue.machineId!,
            startDate: convertDateFormat(dateFilterValue?.startDate || ""),
            endDate: convertDateFormat(dateFilterValue?.endDate || ""),
            limit: tableLimit,
            offset: tableOffset,
        }
        wasteUploadGetListByUserIdApi(dataLimitsObject)
        .then(response => {
            const resData: WasteUploadListResponseBody = response?.data
            if (resData?.status === STATUS_CODES.SUCCESS_CODE) {
                const dataList: WasteUploadData[] = resData.data?.wasteUploadList
                const totalCount : number = resData?.data?.totalCount;
                setDetectionTotalCount(totalCount)
                setPageCount((Math.ceil(totalCount/tableLimit)) || 0);
                setTotalWeight(resData?.data?.totalWeight)
                setWasteUploadList(dataList);     
            }
            else {
                setErrorText(resData?.message)
                console.log ('error');
            }
            setIsLoading(false)
        })
        .catch(error => {
            setSuccessText("");
            setErrorText(error?.response?.data?.message || error?.message)
            setIsLoading(false)
        })
    } 

    const handleChangeFilterValue = (event: ChangeEvent<HTMLSelectElement>) => {
        setFilterValue(
            (prevValue) => {
                return {
                    ...prevValue,
                    [event.target.name]: event.target.value,
                }
            }
        )

        if (event.target.name === "organizationId") {
            setFilterValue(
                (prevFilterData) => {
                    return  {
                        ...prevFilterData,
                        machineId: 0,
                    }
                }
            )
        }

        if (event.target.name === "organizationId" || event.target.name === "machineId") {
            setFilterValue(
                (prevFilterData) => {
                    return  {
                        ...prevFilterData,
                        tenantId: 0,
                    }
                }
            )
        }
    }

    const handleChangeDateFilterValue = (key: "startDate" | "endDate", value: Date) => {
        const day = value.getDate().toString().padStart(2, '0');
        const month = (value.getMonth() + 1).toString().padStart(2, '0');
        const year = value.getFullYear();
        const formattedDate = `${year}-${month}-${day}`;
        setDateFilterValue({...dateFilterValue, [key]: formattedDate})
    }

    const handleChangePage = (event : ChangeEvent<unknown>, pageValue : number) => {
        setTablePage(pageValue);
        if (pageValue === 1) {
            setTableOffset(0);
        }
        else{
            const offsetValue = (tableLimit * (pageValue - 1));
            setTableOffset(offsetValue);
        }
    };

    const handleChangeCurrentWasteUpload = (event: ChangeEvent<any>) => {
        const {name, value} = event.target
        setCurrentWasteUpload(prev => ({
            ...prev,
            [name]: value
        }))

        if (name === "organizationId")
        setCurrentWasteUpload(prev => ({
                ...prev,
                "machineId": 0,
                "tenantId": 0,
            }))
        else if (name === "machineId")
        setCurrentWasteUpload(prev => ({
                ...prev,
                "tenantId": 0,
            }))
    }

    const handleUploadImage = (base64Image: string) => {
        setCurrentWasteUpload(prev => ({
            ...prev,
            imageUris: [base64Image]
        }))
    }

    const addWasteUpload = () => {
        resetAlertMessage()
        const input = {
            organizationId: (isTenantUser() || isAdminUser()) ? userInfo?.orgId : currentWasteUpload?.organizationId,
            tenantId: currentWasteUpload?.tenantId,
            machineId: currentWasteUpload?.machineId,
            wasteType: currentWasteUpload?.wasteType,
            weight: currentWasteUpload?.weight || 0,
            remark: currentWasteUpload?.remark,
            imageUris: currentWasteUpload?.imageUris || [""],
            createdBy: userInfo?.userName || ""
        }
        wasteUploadAddApi(input)
        .then((res) => {
            const response = res?.data
            if (response?.status === STATUS_CODES.SUCCESS_CODE) {
                setSuccessText(response?.message)
                setShowAddModal(false)
                getWasteUploadList()
            } else {
                setErrorText(response?.message)
            }
        }).catch(error => {
            setErrorText(error?.response?.data?.message || error?.message)
        })
    }

    const resetAlertMessage = () => {
        setErrorText("")
        setSuccessText("")
    }

    const updatedWasteUpload = () => {
        resetAlertMessage()
        const input  = {
            id: currentWasteUpload?.id,
            organizationId: currentWasteUpload?.organizationId,
            tenantId: currentWasteUpload?.tenantId,
            machineId: currentWasteUpload?.machineId,
            wasteType: currentWasteUpload?.wasteType,
            weight: currentWasteUpload?.weight || 0,
            remark: currentWasteUpload?.remark,
            imageUri: currentWasteUpload?.imageUris?.[0] || "",
            updatedBy: userInfo?.userName || ""
        }
        wasteUploadUpdateApi(input)
        .then((res) => {
            const response = res?.data
            if (response?.status === STATUS_CODES.SUCCESS_CODE) {
                setSuccessText(response?.message)
                setShowEditModal(false)
                getWasteUploadList()
            } else {
                setErrorText(response?.message)
            }
        }).catch(error => {
            setErrorText(error?.response?.data?.message || error?.message)
        })
    }

    const deleteWasteUpload = () => {
        resetAlertMessage()
        const input = {
            id: currentWasteUpload.id,
            deletedBy: userInfo?.userName || ""
        }
        wasteUploadDeleteApi(input)
        .then((res) => {
            const response = res?.data
            if (response?.status === STATUS_CODES.SUCCESS_CODE) {
                setSuccessText(response?.message)
                setShowDeleteModal(false)
                getWasteUploadList()
            } else {
                setErrorText(response?.message)
            }
        }).catch(error => {
            setErrorText(error?.response?.data?.message || error?.message)
        })
    }

    const handleClickEditBtn = (wasteUpload: WasteUploadData) => {
        const current = {
            id: wasteUpload.id!,
            organizationId: wasteUpload.organizationId,
            machineId: wasteUpload.machineId,
            tenantId: wasteUpload.tenantId,
            wasteType: wasteUpload.wasteType,
            imageUris: [],
            weight: wasteUpload.weight,
            remark: wasteUpload.remark,
        }
        setCurrentWasteUpload(current)
        setShowEditModal(!showEditModal)
    }
    const handleClickDeleteBtn = (wasteUpload: WasteUploadData) => {
        setCurrentWasteUpload(prev => ({
            ...prev,
            id: wasteUpload.id!
        }))
        setShowDeleteModal(!showDeleteModal)
    }

    if (!hasPermission("viewWasteUpload")) {
        return (    
            <Layout>
                <Container>
                    <UnauthorizedPage />
                </Container>
            </Layout>
        )
    }

    return (  
        <Box>
            <Layout>
                <Container>
                    <Title>Waste Upload</Title>
                    <Box className="titleBox">
                        {
                            hasPermission("addWasteUpload") &&
                            <Button 
                                className="pt-2 pb-2 ps-3 pe-3 me-3" 
                                id="btnAddUser" variant="success" 
                                onClick={() => {
                                    setErrorText("")
                                    setCurrentWasteUpload({} as CurrentWasteUpload);
                                    setShowAddModal(!showAddModal)
                                }}>
                                    Add
                            </Button>
                        }
                    </Box>
                    {successText && <Alert severity="success" sx={{marginBottom: 2}}>{successText}<br/></Alert>}
                    {errorText && <Alert severity="error" sx={{marginBottom: 2}}>{errorText}</Alert>}
                    
                    <WasteUploadFilterContainer
                        onChangeFilter={handleChangeFilterValue}
                        onChangeDateFilter={handleChangeDateFilterValue}
                        filterValue={filterValue}
                        dateFilterValue={dateFilterValue}
                    />
                    <AddModal 
                        show={showAddModal}
                        toggleShow={() => {
                            setShowAddModal(!showAddModal)
                        }}
                        wasteUpload={currentWasteUpload}
                        onChange={handleChangeCurrentWasteUpload}
                        onUploadImage={handleUploadImage}
                        onAdd={addWasteUpload}
                    />
                    <EditModal 
                        show={showEditModal}
                        toggleShow={() => {
                            setShowEditModal(!showEditModal)
                        }}
                        wasteUpload={currentWasteUpload}
                        onChange={handleChangeCurrentWasteUpload}
                        onUploadImage={handleUploadImage}
                        onEdit={updatedWasteUpload}
                    />
                    <DeleteModal 
                        wasteUpload={currentWasteUpload}
                        show={showDeleteModal}
                        toggleShow={() => setShowDeleteModal(!showDeleteModal)}
                        onDelete={deleteWasteUpload}
                    />
                    <SuccessToastify 
                        text={successText}
                    />
                    <ErrorToastify 
                        text={errorText}
                    />

                    <SummaryText>Total Weight: {totalWeight || 0}kg</SummaryText>
                    <SummaryText>{detectionTotalCount} Waste Upload(s) found!</SummaryText>
                    <CardsContainer className="hide-scroll-bar">
                        {
                            wasteUplaodList?.length !== 0 && 
                            wasteUplaodList?.map((wastUpload: WasteUploadData) => (
                                <WasteUploadCard 
                                    key={wastUpload.id} 
                                    wasteUpload={wastUpload}
                                    onClickDelete={() => handleClickDeleteBtn(wastUpload)}
                                    onClickEdit={() => handleClickEditBtn(wastUpload)}
                                />
                            ))
                        }
                    </CardsContainer>
                    <LoadingModal 
                        show={isLoading}
                    />
                    <PaginationContainer>
                        <Pagination 
                            count={pageCount} 
                            onChange={handleChangePage} 
                            page={tablePage} 
                            variant="outlined" 
                            shape="rounded" size="large" 
                            disabled={isLoading}
                        />
                    </PaginationContainer>
                </Container>
            </Layout>
        </Box>
    )
}
 
export default WasteUpload;