import { getCurrentLocalUser } from "../utils/UserUtil"
import { UserInfo } from "./UserAuth"

export type CustomWeight = {
    name: string
    totalWeight: number
    deletedAt: Date
}

export type CustomTenantWeight = {
    name: string
    totalWeight: number
    wasteType: string
    weightList: CustomWasteTypeWeight[]
}

export type CustomWasteTypeWeight = {
    name: string
    totalWeight: number
}

export interface BarChartWeightResponseType {
    weightList: CustomWeight[],
}

export interface BarChartTenantWeightResponseType {
    weightList: CustomTenantWeight[],
}

export type BarChartWeightRequestType = {
    orgId: number,
    locationId: number,
    machineId: number,
    tenantId: number,
    startDate: string,
    endDate: string,
    isForAllOrganization: boolean
}

export type TotalWeightByYearRequestType = {
    orgId: number,
    locationId: number,
    machineId: number,
    tenantId: number,
    year: string,
    isForAllOrganization: boolean
}

export type BarChartWeightByDayRequestType = {
    orgId: number,
    locationId: number,
    machineId: number,
    tenantId: number,
    day: string,
    isForAllOrganization: boolean
}

export type RecycleWaste = {
    glassWaste: number,
    plasticWaste: number,
    paperWaste: number,
    metalWaste: number,
    totalRecycleWeight: number,
}
export interface TotalWeightResponseType {
    eWaste: number,
    foodWaste: number,
    generalWaste: number,
    recycleWaste: RecycleWaste,
    totalWeight: number,
    carbonReduction: number
}

export const getDefaultCommonFilters = () => {
    const userInfo: UserInfo = getCurrentLocalUser();
    return {
        org: ''+userInfo.orgId || "-1",
        tenant: {value: "0", label: "All"},
        locations: [],
        machines: [],
        view: "1"
    }
}

export interface HourFilter {
    day: string,
}

export interface DayFilter {
    startDate: string,
    endDate: string,
    range: number
}

export interface MonthFilter {
    month: string,
    year: string,
}

export interface YearFilter {
    year: string
}

export const getDefaultDayFilters = () => {
    const today = new Date();
    const sevenDaysAgo = new Date(today.getTime() - 6 * 24 * 60 * 60 * 1000);
    const startDateDefault = sevenDaysAgo.toISOString().split('T')[0];
    const result: DayFilter = {
        range: 32,
        startDate: startDateDefault,
        endDate: today.toISOString().split('T')[0]
    }

    return result
}

export interface CommonFilter {
    org: string,
    view: string
}
export interface DayFilter {
    startDate: string,
    endDate: string,
    range: number
}

export const DefaultHourFilters = {
    day: new Date().toISOString().split('T')[0]
}

export const DefaultMonthFilters = {
    month: '' + (new Date().getMonth() + 1),
    year: new Date().getFullYear().toString()
}